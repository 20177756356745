import "moment/locale/es";
import "react-datepicker/dist/react-datepicker.css";

import * as Sentry from "@sentry/react";

import { Auth0Provider } from "@auth0/auth0-react";
import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import { loadStripe } from "@stripe/stripe-js";
import es from "date-fns/locale/es";
import moment from "moment";
import momentBusinessDays from "moment-business-days";
import React from "react";
import { registerLocale } from "react-datepicker";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ENV } from "./constants/environment.base";
import theme from "./theme";
import { global_styles } from "./theme/styles";

moment.locale("es");
registerLocale("es", es);
momentBusinessDays.updateLocale("es", {
  holidays: ENV.HOLIDAYS.map((d) => moment(d.date).format("DD-MM-YYYY")),
  holidayFormat: "DD-MM-YYYY",
  prevBusinessDayLimit: 31,
  nextBusinessDayLimit: 31,
});

export const stripePromise = loadStripe(ENV.STRIPE_KEY, {
  locale: "es-ES",
});

Sentry.init({
  dsn: "https://3ebbabee1276af38b9bf73339b28ed84@o4505991750090752.ingest.sentry.io/4505991832403968",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Global styles={global_styles} />
      <Auth0Provider
        domain={ENV.AUTH0_DOMAIN}
        clientId={ENV.AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: ENV.AUTH0_AUDIENCE,
        }}
      >
        <App />
      </Auth0Provider>
    </ChakraProvider>
  </React.StrictMode>
);
