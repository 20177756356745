import {
  Box,
  Button,
  Icon,
  Image,
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  Stepper,
  VStack,
} from "@chakra-ui/react";
import { AnimatePresence, motion } from "framer-motion";
import React, { createContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { IOnboardingContext, useOnboarding } from "../hooks/useOnboarding";

import { Elements } from "@stripe/react-stripe-js";
import { HiArrowLeft } from "react-icons/hi2";
import { stripePromise } from "..";
import { images } from "../assets/images/images";
import OnboardingComplete from "../components/OnboardingComplete";
import OnboardingStepPayment from "../components/OnboardingStepPayment";
import Spinner from "../components/Spinner";

export const OnboardingContext = createContext<IOnboardingContext>(
  {} as IOnboardingContext
);

const Onboarding: React.FC = () => {
  const {
    stepComponents,
    activeStep,
    direction,
    goNextStep,
    goPrevStep,
    data,
    setData,
    menusChosen,
    setMenusChosen,
    planPriceSubtotal,
    planPriceTotal,
    errorMsgs,
    stepSummary,
    setStepSummary,
    stripePaymentIntentClientSecret,
    onboardingSubmitLoading,
    handleOnboardingPetDataChange,
    handleOnboardingStepsSubmit,
  } = useOnboarding();

  return (
    <OnboardingContext.Provider
      value={{
        goNextStep: goNextStep,
        data: data,
        setData: setData,
        menusChosen: menusChosen,
        setMenusChosen: setMenusChosen,
        errorMsgs: errorMsgs[`${activeStep}`],
        setStepSummary: setStepSummary,
        handleOnboardingDogDataChange: handleOnboardingPetDataChange,
        handleOnboardingStepsSubmit: handleOnboardingStepsSubmit,
        onboardingSubmitLoading: onboardingSubmitLoading,
        planPriceSubtotal: planPriceSubtotal,
        planPriceTotal: planPriceTotal,
      }}
    >
      <VStack
        spacing={6}
        minH={"100vh"}
        justifyContent={"flex-start"}
        py={12}
        bgColor={"gray.50"}
      >
        <Routes>
          <Route
            path="/"
            element={
              <>
                {!stepSummary ? (
                  <>
                    {!data ? (
                      <Spinner />
                    ) : (
                      <>
                        <Box h={"10"} pos={"relative"} pl={6}>
                          <Image src={images.logo_candi_yellow} h={"full"} />
                        </Box>

                        <VStack spacing={{ base: 8, md: 14 }} w={"full"}>
                          <Box
                            py={{ base: 3, md: 6 }}
                            px={{ base: 6, md: 12 }}
                            borderRadius={"lg"}
                            bgColor={"white"}
                          >
                            <Stepper
                              colorScheme="brand"
                              index={activeStep - 1}
                              alignSelf={"center"}
                              gap={{ base: 1, md: 5 }}
                            >
                              {stepComponents.map((_, index) => (
                                <Step key={index}>
                                  <StepIndicator>
                                    <StepStatus
                                      complete={<StepIcon />}
                                      incomplete={<StepNumber />}
                                      active={<StepNumber />}
                                    />
                                  </StepIndicator>
                                  <StepSeparator />
                                </Step>
                              ))}
                            </Stepper>
                          </Box>
                          <VStack
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            px={{ base: 4, md: 6 }}
                            w={"full"}
                            maxW={"container.lg"}
                          >
                            <AnimatePresence
                              mode="wait"
                              presenceAffectsLayout={true}
                            >
                              <motion.div
                                key={stepComponents[activeStep - 1].key}
                                initial={{
                                  opacity: 0,
                                  x:
                                    direction?.direction === "forward"
                                      ? 1000
                                      : -1000,
                                }}
                                animate={{
                                  opacity: 1,
                                  x: 0,
                                }}
                                exit={{
                                  opacity: 0,
                                  x:
                                    direction?.direction === "forward"
                                      ? -1000
                                      : 1000,
                                }}
                                transition={{
                                  duration: 0.5,
                                  ease: "easeInOut",
                                }}
                              >
                                {stepComponents[activeStep - 1]}
                              </motion.div>
                            </AnimatePresence>
                          </VStack>
                          {activeStep !== 1 && (
                            <Button
                              variant={"link"}
                              leftIcon={<Icon as={HiArrowLeft} />}
                              onClick={() => goPrevStep()}
                            >
                              Volver
                            </Button>
                          )}
                        </VStack>
                      </>
                    )}
                  </>
                ) : (
                  <VStack
                    spacing={{ base: 6, md: 12 }}
                    justifyContent={"center"}
                    alignItems={"flex-start"}
                    px={{ base: 4, md: 6 }}
                    w={"full"}
                    maxW={"container.xl"}
                  >
                    {/* <Text>Summary</Text> */}
                    {/* <OnboardingStepSummary
                    data={data}
                    setData={setData}
                    planPriceSubtotal={planPriceSubtotal!}
                    planPriceTotal={planPriceTotal!}
                  /> */}
                  </VStack>
                )}
              </>
            }
          />
          <Route
            path="/payment"
            element={
              stripePaymentIntentClientSecret ? (
                <VStack
                  spacing={{ base: 6, md: 12 }}
                  justifyContent={"center"}
                  alignItems={"flex-start"}
                  px={{ base: 4, md: 6 }}
                  w={"full"}
                  maxW={"container.xl"}
                >
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: stripePaymentIntentClientSecret,
                      loader: "always",
                      locale: "es",
                      appearance: {
                        variables: {
                          colorPrimary: "#FED144",
                          borderRadius: "16px",
                        },
                      },
                    }}
                  >
                    <OnboardingStepPayment />
                  </Elements>
                </VStack>
              ) : (
                <Navigate to="/onboarding" />
              )
            }
          />
          <Route
            path="/complete"
            element={<OnboardingComplete data={data} />}
          />
          <Route path="*" element={<Navigate to="/onboarding" />} />
        </Routes>
      </VStack>
    </OnboardingContext.Provider>
  );
};

export default Onboarding;
