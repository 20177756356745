import {
  Button,
  HStack,
  Heading,
  Icon,
  Input,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import { HiChevronRight } from "react-icons/hi2";
import { images } from "../assets/images/images";
import { OnboardingContext } from "../pages/Onboarding";
import OnboardingRadioButtonGroup from "./OnboardingRadioButtonGroup";
import RadioButtonGroup from "./RadioButtonGroup";

const HEADING = {
  singular: "¡Detalles, detalles!",
  plural: "¡Detalles, detalles!",
};

const OnboardingStep3 = () => {
  const { goNextStep, data, handleOnboardingDogDataChange } =
    useContext(OnboardingContext);

  const [validStep, setValidStep] = useState(false);

  useEffect(() => {
    validateStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateStep = () => {
    const valid_pets = data.pets.every(
      (pet) =>
        pet.hasOwnProperty("age_group") &&
        pet.hasOwnProperty("activity_level") &&
        pet.hasOwnProperty("weight") &&
        parseFloat(pet.weight!) >= 1 &&
        parseFloat(pet.weight!) < 150
    );

    setValidStep(valid_pets);
  };

  return (
    <VStack alignItems={"center"} spacing={{ base: 6, md: 12 }} w={"full"}>
      <Heading as={"h1"} fontWeight="bold" fontSize={{ base: 25, md: 40 }}>
        {data.n_of_pets! > 1 ? HEADING.plural : HEADING.singular}
      </Heading>
      <Tabs variant="soft-rounded" colorScheme="secondary" size={"lg"}>
        <TabList
          mx={"auto"}
          mb={4}
          bgColor={"gray.100"}
          p={2}
          borderRadius={"2xl"}
          w={"fit-content"}
        >
          {data.pets.map((pet, petIndex) => (
            <Tab
              key={pet.name}
              _selected={{ color: "white", bgColor: "brand.500" }}
              fontSize={{ base: 20, md: 30 }}
              px={{ base: 4, md: 8 }}
              borderRadius={"2xl"}
              mr={petIndex === data.pets.length - 1 ? 0 : 6}
            >
              {pet.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {data.pets.map((pet, petIndex) => (
            <TabPanel key={pet.name}>
              <VStack spacing={10} alignItems={"center"}>
                <VStack w={"full"} spacing={4}>
                  <Text fontWeight={600} fontSize={"xl"}>
                    ¿Cuál es su edad?
                  </Text>
                  <OnboardingRadioButtonGroup
                    name={"age-group"}
                    value={data.pets[petIndex]?.age_group || ""}
                    columns={{ base: 1, md: 3 }}
                    onChange={(value) =>
                      handleOnboardingDogDataChange({
                        index: petIndex,
                        key: "age_group",
                        value: value,
                      })
                    }
                    options={[
                      {
                        value: "young",
                        caption: "1 a 2 años",
                        icon: images.puppy,
                      },
                      {
                        value: "adult",
                        caption: "3 a 7 años",
                        icon: images.adult,
                      },
                      {
                        value: "senior",
                        caption: "Más de 7 años",
                        icon: images.senior,
                      },
                    ]}
                  />
                </VStack>
                <VStack w={"full"}>
                  <Text fontWeight={600} fontSize={"xl"}>
                    ¿Cómo de activo es?
                  </Text>
                  <RadioButtonGroup
                    name={"activity-level"}
                    value={data.pets[petIndex]?.activity_level || ""}
                    onChange={(value) =>
                      handleOnboardingDogDataChange({
                        index: petIndex,
                        key: "activity_level",
                        value: value,
                      })
                    }
                    options={[
                      {
                        value: "very-low",
                        caption: "Muy bajo (< 30 min diarios)",
                      },
                      {
                        value: "low",
                        caption: "Bajo (entre 30 min y 1 hora diaria)",
                      },
                      {
                        value: "high",
                        caption: "Activo (1 y 2 horas diarias)",
                      },
                      {
                        value: "very-high",
                        caption: "Muy activo (>2 horas diarias)",
                      },
                    ]}
                  />
                </VStack>
                <VStack w={"full"} spacing={4}>
                  <Text fontWeight={600} fontSize={"xl"}>
                    ¿Cómo es su cuerpo?
                  </Text>
                  <OnboardingRadioButtonGroup
                    name={"body-condition"}
                    value={data.pets[petIndex]?.body_condition || ""}
                    columns={{ base: 2, sm: 3, lg: 5 }}
                    onChange={(value) =>
                      handleOnboardingDogDataChange({
                        index: petIndex,
                        key: "body_condition",
                        value: value,
                      })
                    }
                    options={[
                      {
                        value: "very-thin",
                        caption: "Muy delgado",
                        icon: images.very_thin,
                      },
                      {
                        value: "thin",
                        caption: "Delgado",
                        icon: images.thin,
                      },
                      {
                        value: "ideal",
                        caption: "Ideal",
                        icon: images.ideal,
                      },
                      {
                        value: "overweight",
                        caption: "Sobrepeso",
                        icon: images.overweight,
                      },
                      {
                        value: "obese",
                        caption: "Obeso",
                        icon: images.obese,
                      },
                    ]}
                  />
                </VStack>

                <VStack w={"full"}>
                  <Text fontWeight={600} fontSize={"lg"} textAlign={"center"}>
                    ¿Cuánto pesa aproximadamente?
                  </Text>
                  <HStack spacing={4}>
                    <Input
                      size={"lg"}
                      type={"number"}
                      step={".1"}
                      w={100}
                      value={data.pets[petIndex]?.weight || ""}
                      onChange={(e) =>
                        handleOnboardingDogDataChange({
                          index: petIndex,
                          key: "weight",
                          value: e.currentTarget.value,
                        })
                      }
                    />
                    <Text fontWeight={"semibold"} fontSize={"xl"}>
                      Kg
                    </Text>
                  </HStack>
                </VStack>
              </VStack>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>

      <Button
        size={"lg"}
        borderRadius={"full"}
        color={"white"}
        colorScheme="brand"
        type="submit"
        rightIcon={<Icon as={HiChevronRight} ml={10} />}
        justifyContent={"space-between"}
        isDisabled={!validStep}
        onClick={() => goNextStep()}
      >
        Continuar
      </Button>
    </VStack>
  );
};

export default OnboardingStep3;
