import axios, { AxiosResponse } from "axios";
import { IPet } from "../types/types";

import { ENV } from "../constants/environment.base";

export class PetService {
  private readonly API_ROUTE_PREFIX: string = "pet";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ENDPOINT;

  /**
   * List all pets
   *
   * @returns Promise with pets response
   */
  public list: (options?: {
    expand?: Array<string>;
  }) => Promise<AxiosResponse<IPet[]>> = (options) => {
    return axios.get(`${this.API_PREFIX}/list`, {
      params: { ...options },
    });
  };
}
