import { useElements, useStripe } from "@stripe/react-stripe-js";
import {
  StripeAddressElementChangeEvent,
  StripePaymentElementChangeEvent,
} from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";

import CryptoJS from "crypto-js";
import moment from "moment";
import momentBusinessDays from "moment-business-days";
import { useNavigate } from "react-router-dom";
import { ENV } from "../constants/environment.base";
import { calculateTransitionDeliveryDate } from "../constants/utils";
import { OnboardingContext } from "../pages/Onboarding";
import { OnboardingService } from "../services/onboarding.service";
import { IPetOrder } from "../types/types";
import { IOnboardingData } from "./useOnboarding";

interface IUseOnboardingStepPaymentReturn {
  validStep: boolean;
  errorMessages: string[];
  showLocationWarning: boolean;
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  planStartDate: Date;
  setPlanStartDate: React.Dispatch<React.SetStateAction<Date>>;
  maxCapacityDates: Date[];
  setStripeAddressElement: React.Dispatch<
    React.SetStateAction<StripeAddressElementChangeEvent | undefined>
  >;
  setStripePaymentElement: React.Dispatch<
    React.SetStateAction<StripePaymentElementChangeEvent | undefined>
  >;
  onboardingSubmitLoading: boolean;
  getDogMenuText: (
    pet_order: IPetOrder,
    period: "daily" | "monthly",
    onboarding?: boolean
  ) => string | undefined;
  handleOnboardingComplete: () => Promise<null | undefined>;
  data: IOnboardingData;
  setData: React.Dispatch<React.SetStateAction<IOnboardingData>>;
  planPriceSubtotal: number | undefined;
  planPriceTotal: number | undefined;
}

export interface IOnboardingStepPaymentTab {
  onboarding?: boolean;
  getDogMenuText: (
    pet_order: IPetOrder,
    period: "daily" | "monthly",
    onboarding?: boolean
  ) => string | undefined;
}

export const INCLUDED_FEATURES = [
  "Contacto con nutricionistas a cualquier hora, cualquier día, de manera gratuita",
  "Control de tu plan de alimentación desde tu perfil de usuario, sin permanencia",
  "Elige las cantidades y fechas de entrega de la comida de tu peludo, con total libertad",
];

const onboardingService = new OnboardingService();

/**
 *
 * Hook definition
 *
 */
const useOnboardingStepPayment: () => IUseOnboardingStepPaymentReturn = () => {
  const { data, setData, menusChosen, planPriceSubtotal, planPriceTotal } =
    useContext(OnboardingContext);

  const navigate = useNavigate();

  const stripe = useStripe();
  const elements = useElements();

  const [validStep, setValidStep] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [showLocationWarning, setShowLocationWarning] =
    useState<boolean>(false);

  const [password, setPassword] = useState<string>("");
  const [planStartDate, setPlanStartDate] = useState<Date>(
    calculateTransitionDeliveryDate()
  );

  const [stripeAddressElement, setStripeAddressElement] = useState<
    StripeAddressElementChangeEvent | undefined
  >();
  const [stripePaymentElement, setStripePaymentElement] = useState<
    StripePaymentElementChangeEvent | undefined
  >();
  const [onboardingSubmitLoading, setOnboardingSubmitLoading] =
    useState<boolean>(false);

  const [maxCapacityDates, setMaxCapacityDates] = useState<Date[]>([]);

  useEffect(() => {
    onboardingService.max_capacity_dates(new Date()).then(({ data }) => {
      momentBusinessDays.updateLocale("es", {
        holidays: [
          ...ENV.HOLIDAYS.map((d) => moment(d.date).format("DD-MM-YYYY")),
          ...data.max_capacity_dates.map((d) => moment(d).format("DD-MM-YYYY")),
        ],
        holidayFormat: "DD-MM-YYYY",
        prevBusinessDayLimit: 31,
        nextBusinessDayLimit: 31,
      });

      setMaxCapacityDates(data.max_capacity_dates);
    });
  }, []);

  useEffect(() => {
    validateStep();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    password,
    data,
    stripeAddressElement,
    stripePaymentElement,
    planStartDate,
  ]);

  const validateStep = () => {
    let cleanErrorMessages: string[] = [];

    const validLocation =
      stripeAddressElement?.value.address.postal_code.startsWith("35") &&
      stripeAddressElement?.value.address.state === "GC";

    const validPlanStartDate = moment(planStartDate).isSameOrAfter(
      moment(new Date()).add(4, "d")
    );

    const validPassword =
      password.length >= 8 &&
      password.match(/[a-z]/) &&
      password.match(/[A-Z]/) &&
      password.match(/[0-9]/);

    const valid =
      stripePaymentElement?.complete &&
      stripeAddressElement?.complete &&
      validLocation &&
      validPlanStartDate &&
      validPassword;

    setShowLocationWarning(
      stripeAddressElement?.complete !== true ? false : !validLocation
    );

    if (!validPlanStartDate) {
      cleanErrorMessages.push(
        `La fecha debe ser el ${moment(new Date())
          .add(5, "d")
          .format("DD/MM")} o posterior`
      );
    }

    if (!validPassword && password.length > 0) {
      cleanErrorMessages.push(
        `La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula y un número`
      );
    }

    setErrorMessages([...cleanErrorMessages]);
    setValidStep(Boolean(valid));
  };

  const getDogMenuText = (
    pet_order: IPetOrder,
    period: "daily" | "monthly",
    onboarding: boolean = false
  ): string | undefined => {
    const daily_bags: { [grams: string]: number } = pet_order.daily_bags;

    if (period === "daily") {
      let string = "Debes darle diariamente ";

      Object.keys(daily_bags).forEach((key, keyIndex) => {
        if (
          keyIndex === Object.keys(daily_bags).length - 1 &&
          keyIndex !== 0 &&
          Object.keys(daily_bags).length !== 1
        ) {
          string += ` y ${daily_bags[key]} ${
            daily_bags[key] > 1 ? "bolsitas" : "bolsita"
          } de ${key}g`;
          return;
        }

        string += `${daily_bags[key]} ${
          daily_bags[key] > 1 ? "bolsitas" : "bolsita"
        } de ${key}g`;
      });

      return (string += ".");
    } else if (period === "monthly") {
      const monthly_bags: { [grams: string]: number } = {};
      Object.keys(pet_order.daily_bags).forEach((key) => {
        onboarding
          ? (monthly_bags[key] = daily_bags[key] * 14)
          : (monthly_bags[key] = daily_bags[key] * 28);
      });

      let string = "Recibirás";

      Object.keys(monthly_bags).forEach((key, keyIndex) => {
        if (
          keyIndex === Object.keys(monthly_bags).length - 1 &&
          keyIndex !== 0 &&
          Object.keys(monthly_bags).length !== 1
        ) {
          string += ` y ${monthly_bags[key]} ${
            monthly_bags[key] > 1 ? "bolsitas" : "bolsita"
          } de ${key}g`;
          return;
        }

        string += ` ${monthly_bags[key]} ${
          monthly_bags[key] > 1 ? "bolsitas" : "bolsita"
        } de ${key}g`;
      });

      if (!onboarding && pet_order.deliveries > 1) {
        string += ` en ${pet_order.deliveries} entregas`;
      }

      return (string += ".");
    }
  };

  const handleOnboardingComplete = async () => {
    setOnboardingSubmitLoading(true);
    let cleanErrorMessages: string[] = [];

    if (!stripe || !elements) {
      return null;
    }

    await stripe
      .confirmPayment({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: "http://localhost:3000/onboarding",
          payment_method_data: {
            billing_details: {
              name: stripeAddressElement?.value.name as string,
              email: data.user.user_email as string,
              phone: data.user.user_phone_number as string,
              address: {
                line1: stripeAddressElement?.value.address.line1 || "",
                line2: stripeAddressElement?.value.address.line2 || "",
                city: stripeAddressElement?.value.address.city || "",
                state: stripeAddressElement?.value.address.state || "",
                postal_code:
                  stripeAddressElement?.value.address.postal_code || "",
                country: "ES",
              },
            },
          },
        },
      })
      .then(({ error, paymentIntent }) => {
        if (error) {
          setErrorMessages([...cleanErrorMessages, error.message!]);
          setOnboardingSubmitLoading(false);
          return;
        }

        if (!paymentIntent || !paymentIntent.client_secret) {
          console.error("No payment intent client secret found");
          setErrorMessages([
            ...cleanErrorMessages,
            "No se ha podido procesar el pago, por favor recarga la página e inténtalo de nuevo.",
          ]);
          setOnboardingSubmitLoading(false);
          return;
        }

        stripe
          .retrievePaymentIntent(paymentIntent.client_secret)
          .then(({ paymentIntent }) => {
            console.log("paymentIntent", paymentIntent);

            switch (paymentIntent?.status) {
              case "succeeded":
                console.log("Payment succeded");

                var encrypted_pwd = encodeURI(
                  CryptoJS.AES.encrypt(password, ENV.ENCRYPTION_KEY).toString()
                );

                onboardingService
                  .onboarding_complete({
                    stripe_id: data.user.stripe!,
                    auth0_password: encrypted_pwd,
                    name: {
                      given_name: stripeAddressElement?.value.firstName!,
                      family_name: stripeAddressElement?.value.lastName!,
                    },
                    address: {
                      city: stripeAddressElement?.value.address.city!,
                      line1: stripeAddressElement?.value.address.line1!,
                      line2: stripeAddressElement?.value.address.line2!,
                      postal_code:
                        stripeAddressElement?.value.address.postal_code!,
                      state: stripeAddressElement?.value.address.state!,
                    },
                    plan_price_total: planPriceTotal!,
                    total_daily_grams: data.pet_orders.reduce(
                      (acc, pet_order) => acc + pet_order.total_daily_grams,
                      0
                    ),
                    plan_start_date: planStartDate.toDateString(),
                    payment_id: paymentIntent.id,
                    menus: menusChosen,
                  })
                  .then(({ data }) => {
                    console.log("data", data);

                    if (!data.ok) {
                      setErrorMessages([
                        ...cleanErrorMessages,
                        "Ha ocurrido un error completando el registro, por favor recarga la página y vuelve a intentarlo",
                      ]);
                    }

                    localStorage.removeItem("onboarding__activeStep");
                    localStorage.removeItem("onboarding__data");
                    localStorage.removeItem("onboarding__stripe_pi_secret");
                    localStorage.removeItem("onboarding__plan_price_subtotal");
                    localStorage.removeItem("onboarding__plan_price_total");

                    navigate("/onboarding/complete");
                  })
                  .catch((err) => {
                    setErrorMessages([
                      ...cleanErrorMessages,
                      "Ha ocurrido un error completando el registro, por favor recarga la página y vuelve a intentarlo",
                    ]);
                  })
                  .finally(() => {
                    setOnboardingSubmitLoading(false);
                  });

                break;

              case "processing":
                console.log("paymentIntent?.status, PROCESSING");

                break;

              case "requires_payment_method":
                console.log("paymentIntent?.status, REQUIRES_PAYMENT_METHOD");

                break;

              default:
                break;
            }
          });
      })
      .catch((err) => {
        setOnboardingSubmitLoading(false);

        console.error(err);
        setErrorMessages([
          ...cleanErrorMessages,
          "Ha ocurrido un error completando el registro, por favor recarga la página y vuelve a intentarlo",
        ]);
      });
  };

  return {
    validStep,
    errorMessages,
    showLocationWarning,
    password,
    setPassword,
    planStartDate,
    setPlanStartDate,
    maxCapacityDates,
    setStripeAddressElement,
    setStripePaymentElement,
    onboardingSubmitLoading,
    getDogMenuText,
    handleOnboardingComplete,
    data,
    setData,
    planPriceSubtotal,
    planPriceTotal,
  };
};

export default useOnboardingStepPayment;
