import {
  Box,
  HStack,
  Heading,
  Icon,
  Link,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";

import Auth0 from "auth0";
import { Helmet } from "react-helmet";
import { HiChevronRight } from "react-icons/hi2";
import { NavLink as RouterNavLink } from "react-router-dom";
import { UserContext } from "../App";
import { ENV } from "../constants/environment.base";
import { IUser } from "../types/types";

const Home: React.FC = () => {
  const { user } = useContext(UserContext);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Inicio - Candi Dog Menu</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <VStack w={"full"} spacing={8} alignItems={"start"} p={4}>
        <Heading as="h1" size="xl">
          ¡Hola, {((user as IUser).auth0 as Auth0.User).given_name}!
        </Heading>
        {/* <SimpleGrid
          minChildWidth={{ base: "full", md: "280px" }}
          spacing={4}
          bgImage={images.auth_bg_pattern}
          w={"full"}
          minH={"100px"}
          borderRadius={"2xl"}
          borderWidth={3}
          borderColor={"brand.500"}
          p={4}
        >
          {[1, 2].map((i) => (
            <VStack
              borderRadius={"2xl"}
              w={"full"}
              p={4}
              spacing={2}
              alignItems={"flex-start"}
              justifyContent={"flex-start"}
              bgColor={"white"}
              borderWidth={3}
              borderColor={"brand.500"}
            >
              <Heading size={"lg"}>12 días</Heading>
              <Text color={"gray.600"} fontSize={14}>
                para que tu plan se renueve
              </Text>
            </VStack>
          ))}
        </SimpleGrid> */}
        <VStack spacing={2} w={"full"} h={"fit-content"} textAlign={"start"}>
          {ENV.NAVBAR_LINKS.filter((i) => i.caption !== "Inicio").map((i) => (
            <Link
              key={i.caption}
              as={RouterNavLink}
              to={i.destination}
              fontWeight={"medium"}
              w={"full"}
            >
              <HStack
                p={4}
                borderRadius={"2xl"}
                bgColor={"gray.50"}
                spacing={6}
                w={"full"}
                h={"full"}
                justifyContent={"flex-start"}
                alignItems={"center"}
              >
                <Box
                  p={3}
                  bgColor={i.accentColor}
                  borderRadius={"lg"}
                  h={"40px"}
                  w={"40px"}
                >
                  <Icon as={i.icon} strokeWidth={1.5} h={"100%"} w={"100%"} />
                </Box>
                <VStack spacing={0} alignItems={"flex-start"} w={"full"}>
                  <Text fontSize={"lg"} fontWeight={"semibold"}>
                    {i.caption}
                  </Text>
                  <Text
                    textAlign={"start"}
                    fontSize={"smaller"}
                    fontWeight={"normal"}
                    whiteSpace={"normal"}
                  >
                    {i.description}
                  </Text>
                </VStack>
                <Icon as={HiChevronRight} strokeWidth={1} h={3} w={3} />
              </HStack>
            </Link>
          ))}
        </VStack>
      </VStack>
    </>
  );
};

export default Home;
