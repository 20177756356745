import {
  Box,
  Button,
  Heading,
  Highlight,
  Icon,
  Image,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

import React from "react";
import { HiChevronRight } from "react-icons/hi2";
import { images } from "../assets/images/images";
import { IOnboardingData } from "../hooks/useOnboarding";

interface IUseOnboardingStepSummary {
  data: IOnboardingData;
}

const OnboardingComplete: React.FC<IUseOnboardingStepSummary> = ({ data }) => {
  const navigate = useNavigate();

  return (
    <VStack
      justifyContent={"center"}
      spacing={{ base: 6, md: 12 }}
      w={"full"}
      p={{ base: 4, md: 6 }}
    >
      <VStack alignItems={"center"} spacing={8}>
        <Image src={images.orange_dog} h={150} />
        <Heading
          as={"h1"}
          fontWeight={"800"}
          fontSize={{ base: 25, md: 40 }}
          textAlign={"center"}
        >
          <Highlight
            query="Transforma"
            styles={{
              px: "2",
              py: "1",
              rounded: "3xl",
              bg: "brand.200",
            }}
          >
            {`¡Ya hemos terminado${
              data.user.first_name ? `, ${data.user.first_name}` : ""
            }!`}
          </Highlight>
        </Heading>
      </VStack>
      <VStack justifyContent={"center"} spacing={{ base: 2, md: 4 }} w={"full"}>
        <Text
          textAlign={"center"}
          fontWeight="medium"
          fontSize={{ base: 14, md: 16 }}
        >
          Desde ya formas parte de la revolución de la alimentación canina en
          Canarias.
        </Text>
        <Text
          textAlign={"center"}
          color={"gray.700"}
          fontSize={{ base: 13, md: 15 }}
        >
          Puedes{" "}
          <Link to={"/auth"} state={{ email: data.user.user_email }}>
            <Box
              as={"span"}
              fontWeight={"medium"}
              color={"brand.600"}
              textDecoration={"underline"}
            >
              acceder a tu cuenta
            </Box>
          </Link>{" "}
          y administrar tus datos y envíos de comida.
        </Text>
      </VStack>
      <Wrap spacing="30px" justify="center">
        <WrapItem>
          <Button
            w={"full"}
            borderRadius={"full"}
            justifyContent={"space-between"}
            size={"lg"}
            colorScheme="brand"
            rightIcon={<Icon as={HiChevronRight} />}
            onClick={() => {
              navigate("/auth", { state: { email: data.user.user_email } });
            }}
          >
            Iniciar sesión
          </Button>
        </WrapItem>
      </Wrap>
    </VStack>
  );
};

export default OnboardingComplete;
