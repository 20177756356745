const Input = {
  sizes: {
    md: {
      field: {
        // p: "12px",
        // paddingInline: "12px !important",
        borderRadius: "xl",
      },
    },
    lg: {
      field: {
        borderRadius: "2xl",
      },
    },
  },
  defaultProps: { bgColor: "white" },
};

export default Input;
