import { Box, Spinner as ChakraSpinner } from "@chakra-ui/react";

const Spinner = () => {
  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      pos={"absolute"}
      w={"full"}
      h={"full"}
      top={0}
      left={0}
      backgroundColor={"gray.100"}
      blur={"md"}
    >
      <ChakraSpinner
        thickness="3px"
        speed="0.65s"
        emptyColor="brand.100"
        color="brand.500"
        size="lg"
      />
    </Box>
  );
};

export default Spinner;
