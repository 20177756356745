import {
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Image,
  Skeleton,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import { IoMdAddCircleOutline, IoMdFemale, IoMdMale } from "react-icons/io";

import React from "react";
import { Helmet } from "react-helmet";
import { images } from "../assets/images/images";
import PageHeader from "../components/PageHeader";
import { ENV } from "../constants/environment.base";
import Section from "../containers/Section";
import { usePets } from "../hooks/usePets";
import { IPet } from "../types/types";

const Pets: React.FC = () => {
  const { pets, petsLoading, petOrders, getDogMenuText } = usePets();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Peludos - Candi Dog Menu</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <VStack
        w={"full"}
        spacing={8}
        alignItems={"start"}
        justifyContent={"start"}
        textAlign={"start"}
      >
        <PageHeader title={"Peludos"} />
        <VStack w={"full"} spacing={8} alignItems={"start"}>
          <Section>
            <Skeleton
              isLoaded={!petsLoading}
              as={Grid}
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(12, 1fr)",
              }}
              gap={4}
              w={"full"}
            >
              {pets &&
                pets.map((pet) => (
                  <GridItem
                    key={pet._id}
                    colSpan={{ base: 12, md: 4 }}
                    bgColor={"white"}
                    p={6}
                    borderRadius={"2xl"}
                  >
                    <Heading as={"h2"} fontSize={"xl"}>
                      {pet.name}
                    </Heading>
                    <HStack spacing={2} width={"full"} mt={2}>
                      <Icon
                        as={pet.gender === "male" ? IoMdMale : IoMdFemale}
                        w={5}
                        h={5}
                        strokeWidth={1}
                        color={"gray.500"}
                      />
                      <Text color={"gray.500"} fontWeight={"medium"}>
                        {
                          ENV.DOG_BREEDS.find((b) => b.code === pet.breed_code)
                            ?.name
                        }
                      </Text>
                    </HStack>
                    <Text mt={4}>
                      Pesa {pet.weight} Kg y{" "}
                      {getDogMenuText(
                        petOrders.filter(
                          (po) => (po.pet as IPet)._id === pet._id
                        )[0].daily_bags
                      )}
                    </Text>
                    <VStack mt={4} alignItems={"flex-start"}>
                      <Text fontWeight={"semibold"}>Menú</Text>
                      <VStack alignItems={"flex-start"}>
                        <HStack divider={<StackDivider color={"gray.200"} />}>
                          <HStack>
                            <Image src={images.chicken} h={"20px"} />
                            <Text fontWeight={"medium"}>Pollo</Text>
                          </HStack>
                          <Text>
                            {
                              petOrders.filter(
                                (po) => (po.pet as IPet)._id === pet._id
                              )[0].menu.chicken
                            }{" "}
                            envases
                          </Text>
                        </HStack>
                        <HStack divider={<StackDivider color={"gray.200"} />}>
                          <HStack>
                            <Image src={images.beef} h={"20px"} />
                            <Text fontWeight={"medium"}>Ternera</Text>
                          </HStack>
                          <Text>
                            {
                              petOrders.filter(
                                (po) => (po.pet as IPet)._id === pet._id
                              )[0].menu.beef
                            }{" "}
                            envases
                          </Text>
                        </HStack>
                        {/* <HStack divider={<StackDivider color={"gray.200"} />}>
                          <HStack>
                            <Image src={images.pig} h={"20px"} />
                            <Text fontWeight={"medium"}>Pavo</Text>
                          </HStack>
                          <Text>
                            {
                              petOrders.filter(
                                (po) => (po.pet as IPet)._id === pet._id
                              )[0].menu.pig
                            }{" "}
                            envases
                          </Text>
                        </HStack> */}
                      </VStack>
                    </VStack>
                  </GridItem>
                ))}
              <GridItem
                colSpan={{ base: 12, md: 4 }}
                bgColor={"white"}
                p={6}
                borderRadius={"2xl"}
              >
                <VStack
                  alignItems={"center"}
                  justifyContent={"center"}
                  h={"full"}
                  p={8}
                >
                  <Icon
                    as={IoMdAddCircleOutline}
                    w={25}
                    h={25}
                    strokeWidth={0.5}
                    color={"gray.500"}
                  />
                  <Text fontSize={14} textAlign={"center"} color={"gray.500"}>
                    Si quieres editar o añadir otro peludo más, contacta con
                    nosotros en{" "}
                    <a
                      href={`mailto:ayuda@candidogmenu.com?subject=¡Un nuevo peludo en Candi!`}
                      style={{ textDecoration: "underline" }}
                    >
                      ayuda@candidogmenu.com
                    </a>{" "}
                    y te ayudaremos
                  </Text>
                </VStack>
              </GridItem>
            </Skeleton>
          </Section>
        </VStack>
      </VStack>
    </>
  );
};

export default Pets;
