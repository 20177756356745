import {
  Button,
  HStack,
  Heading,
  Icon,
  Input,
  SimpleGrid,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { IoMdFemale, IoMdMale } from "react-icons/io";

import { HiChevronRight } from "react-icons/hi2";
import { OnboardingContext } from "../pages/Onboarding";

const GENDER_BUTTONS: {
  caption: "Macho" | "Hembra";
  value: "male" | "female";
  icon: React.ElementType;
}[] = [
  { caption: "Macho", value: "male", icon: IoMdMale },
  { caption: "Hembra", value: "female", icon: IoMdFemale },
];

const STERILIZED_BUTTONS: {
  caption: "Sí" | "No";
  value: boolean;
}[] = [
  { caption: "Sí", value: true },
  { caption: "No", value: false },
];

const HEADING = {
  singular: "Preséntanos a tu peludo",
  plural: "Preséntanos a tus peludos",
};

const OnboardingStep2 = () => {
  const { goNextStep, data, handleOnboardingDogDataChange } =
    useContext(OnboardingContext);

  const [validStep, setValidStep] = useState(false);

  useEffect(() => {
    validateStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateStep = () => {
    const valid_pets = data.pets.every(
      (pet) =>
        pet.hasOwnProperty("name") &&
        pet.name !== "" &&
        pet.hasOwnProperty("gender") &&
        pet.hasOwnProperty("sterilized")
    );

    setValidStep(valid_pets);
  };

  return (
    <VStack alignItems={"center"} spacing={{ base: 6, md: 12 }} w={"full"}>
      <Heading as={"h1"} fontWeight="bold" fontSize={{ base: 25, md: 40 }}>
        {data.n_of_pets! > 1 ? HEADING.plural : HEADING.singular}
      </Heading>
      <VStack
        divider={<StackDivider color={"gray.200"} />}
        w={"full"}
        spacing={8}
      >
        {Array(data.n_of_pets)
          .fill(0)
          .map((_, petIndex) => (
            <SimpleGrid
              key={`dog-${petIndex}`}
              spacing={{ base: 4, lg: 8 }}
              columns={{ base: 1, lg: 3 }}
              alignItems={"flex-end"}
              w={"full"}
            >
              <VStack alignItems={"start"}>
                <Text fontWeight={400}>¿Cuál es su nombre?</Text>
                <Input
                  type="text"
                  size={{ base: "md", md: "lg" }}
                  value={data.pets[petIndex]?.name || ""}
                  onChange={(e) => {
                    handleOnboardingDogDataChange({
                      index: petIndex,
                      key: "name",
                      value: e.target.value,
                    });
                  }}
                />
              </VStack>

              <VStack alignItems={"start"}>
                <Text fontWeight={400}></Text>
                <HStack>
                  {GENDER_BUTTONS.map((gender) => (
                    <Button
                      key={gender.caption}
                      size={{ base: "md", md: "lg" }}
                      variant={"outline"}
                      border="2px"
                      colorScheme="secondary"
                      borderRadius={"xl"}
                      borderColor="secondary.500"
                      color="secondary.500"
                      _active={{ bgColor: "secondary.200", color: "white" }}
                      leftIcon={<Icon as={gender.icon} strokeWidth={1} />}
                      isActive={data.pets[petIndex]?.gender === gender.value}
                      onClick={() => {
                        handleOnboardingDogDataChange({
                          index: petIndex,
                          key: "gender",
                          value: gender.value,
                        });
                      }}
                    >
                      {gender.caption}
                    </Button>
                  ))}
                </HStack>
              </VStack>
              <VStack alignItems={"start"}>
                <Text fontWeight={400}>¿Está esterilizado?</Text>
                <HStack>
                  {STERILIZED_BUTTONS.map((option) => (
                    <Button
                      key={option.caption}
                      size={{ base: "md", md: "lg" }}
                      variant={"outline"}
                      border="2px"
                      colorScheme="secondary"
                      borderRadius={"xl"}
                      borderColor="secondary.500"
                      color="secondary.500"
                      _active={{ bgColor: "secondary.200", color: "white" }}
                      isActive={
                        data.pets[petIndex]?.sterilized === option.value
                      }
                      onClick={() => {
                        handleOnboardingDogDataChange({
                          index: petIndex,
                          key: "sterilized",
                          value: option.value,
                        });
                      }}
                    >
                      {option.caption}
                    </Button>
                  ))}
                </HStack>
              </VStack>
            </SimpleGrid>
          ))}
      </VStack>
      <Button
        size={"lg"}
        borderRadius={"full"}
        color={"white"}
        colorScheme="brand"
        type="submit"
        rightIcon={<Icon as={HiChevronRight} ml={10} />}
        justifyContent={"space-between"}
        isDisabled={!validStep}
        onClick={() => goNextStep()}
      >
        Continuar
      </Button>
    </VStack>
  );
};

export default OnboardingStep2;
