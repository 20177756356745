import { Box, HTMLChakraProps, Heading, VStack } from "@chakra-ui/react";
import React, { ReactElement } from "react";

const Section: React.FC<{
  children: ReactElement;
  title?: string;
  chakraStyles?: HTMLChakraProps<"div">;
}> = ({ children, title, chakraStyles }) => {
  const defaultStyles = { bg: "gray.50", w: "full", p: 4, borderRadius: "2xl" };

  return (
    <>
      <VStack
        textAlign={"start"}
        w={"full"}
        alignItems={"start"}
        justifyContent={"start"}
        px={4}
      >
        {title && (
          <Heading as="h2" size={"lg"} fontWeight={"semibold"} mt={0}>
            {title}
          </Heading>
        )}
        <Box {...{ ...defaultStyles, ...chakraStyles }}>{children}</Box>
      </VStack>
    </>
  );
};

export default Section;
