import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Skeleton,
  StackDivider,
  Text,
  VStack,
  Wrap,
  WrapItem,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { IPet, IUserExpanded } from "../types/types";

import moment from "moment";
import { Helmet } from "react-helmet";
import { HiChevronRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Stripe from "stripe";
import { UserContext } from "../App";
import EditAddress from "../components/EditAddress";
import PageHeader from "../components/PageHeader";
import RadioButtonGroup from "../components/RadioButtonGroup";
import { ENV } from "../constants/environment.base";
import Section from "../containers/Section";
import { useDeliveries } from "../hooks/useDeliveries";
import { PetOrderService } from "../services/pet_order.service";
import { UserService } from "../services/user.service";

const userService = new UserService();
const petOrdersService = new PetOrderService();

const Deliveries: React.FC = () => {
  const {
    user,
    petOrders,
    deliveriesPending,
    deliveriesPendingLoading,
    deliveriesHistory,
    deliveriesHistoryLoading,
    deliveriesActions,
    isDeliveriesPostponeModalOpen,
    onDeliveriesPostponeModalOpen,
    onDeliveriesPostponeModalClose,
    isDeliveriesAddressModalOpen,
    onDeliveriesAddressModalOpen,
    onDeliveriesAddressModalClose,
    isDeliveriesPauseOpen,
    onDeliveriesPauseOpen,
    onDeliveriesPauseClose,
    isDeliveriesFrequencyOpen,
    onDeliveriesFrequencyOpen,
    onDeliveriesFrequencyClose,
  } = useDeliveries();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Envíos - Candi Dog Menu</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <VStack
        w={"full"}
        spacing={8}
        alignItems={"start"}
        justifyContent={"start"}
        textAlign={"start"}
      >
        <PageHeader title={"Envíos"} />
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
          gap={4}
          w={"full"}
        >
          <GridItem colSpan={{ base: 12, md: 8 }}>
            <VStack w={"full"} spacing={8} alignItems={"start"}>
              <Section title="Próximos envíos">
                <Skeleton
                  isLoaded={!deliveriesPendingLoading}
                  as={VStack}
                  divider={<StackDivider color={"gray.200"} />}
                  alignItems={"flex-start"}
                >
                  {deliveriesPending &&
                    deliveriesPending.map((delivery) => (
                      <Link
                        key={delivery._id}
                        to={`/deliveries/${delivery._id}`}
                        style={{ width: "100%" }}
                      >
                        <HStack
                          justifyContent={"space-between"}
                          w={"full"}
                          spacing={4}
                        >
                          <VStack
                            pr={2}
                            borderRight={"2px solid"}
                            borderRightColor={"gray.200"}
                            minW={"110px"}
                            spacing={0}
                            alignItems={"flex-start"}
                          >
                            <Text fontWeight={"semibold"}>
                              {moment(delivery.delivery_date).fromNow()}
                            </Text>
                            <Text fontWeight={"medium"} color={"gray.500"}>
                              {moment(delivery.delivery_date).format("D")}{" "}
                              {moment(delivery.delivery_date).format("MMM")}
                            </Text>
                          </VStack>
                          <VStack
                            alignItems={"flex-start"}
                            spacing={0}
                            flex={1}
                          >
                            <Text fontWeight={"medium"}>
                              {delivery.content.bag_count} bolsas para{" "}
                              {(delivery.pet as IPet).name}
                            </Text>
                            {delivery.is_trial && (
                              <Text color={"gray.500"}>
                                Pedido de transición
                              </Text>
                            )}
                          </VStack>

                          <Badge
                            colorScheme={
                              delivery.status === "cancelled"
                                ? "red"
                                : delivery.status === "delivered"
                                ? "green"
                                : "yellow"
                            }
                          >
                            {ENV.DELIVERY_KEY_CAPTIONS[delivery.status]}
                          </Badge>
                          <HiChevronRight />
                        </HStack>
                      </Link>
                    ))}
                </Skeleton>
              </Section>
              <Section title="Últimos envíos">
                <Skeleton
                  isLoaded={!deliveriesHistoryLoading}
                  as={VStack}
                  divider={<StackDivider color={"gray.200"} />}
                  alignItems={"flex-start"}
                >
                  {deliveriesHistory ? (
                    deliveriesHistory?.length === 0 ? (
                      <Box py={4}>
                        <Text color={"gray.500"}>
                          Aún no hay pedidos pasados
                        </Text>
                      </Box>
                    ) : (
                      deliveriesHistory.map((delivery) => (
                        <Link
                          key={delivery._id}
                          to={`/deliveries/${delivery._id}`}
                          style={{ width: "100%" }}
                        >
                          <HStack
                            justifyContent={"space-between"}
                            w={"full"}
                            spacing={4}
                          >
                            <VStack
                              alignItems={"flex-start"}
                              spacing={0}
                              flex={1}
                            >
                              <HStack alignItems={"center"}>
                                <Text fontWeight={"semibold"}>
                                  {moment(delivery.delivery_date).format(
                                    "D MMM YYYY"
                                  )}
                                </Text>
                              </HStack>
                              <Text>
                                {delivery.content.bag_count} bolsas para{" "}
                                {(delivery.pet as IPet).name}
                              </Text>
                            </VStack>
                            <Badge
                              colorScheme={
                                delivery.status === "pending" ||
                                delivery.status === "packaged"
                                  ? "yellow"
                                  : delivery.status === "delivered"
                                  ? "green"
                                  : "red"
                              }
                            >
                              {ENV.DELIVERY_KEY_CAPTIONS[delivery.status]}
                            </Badge>
                            <HiChevronRight />
                          </HStack>
                        </Link>
                      ))
                    )
                  ) : (
                    <></>
                  )}
                </Skeleton>
              </Section>
            </VStack>
          </GridItem>
          <GridItem colSpan={{ base: 12, md: 4 }}>
            <Section
              title="Gestiona tus envíos"
              chakraStyles={{ bgColor: "transparent", p: 0 }}
            >
              <VStack spacing={4} w={"full"}>
                {deliveriesActions.map((action) => {
                  if (
                    action.id === "frequency_change" &&
                    petOrders.every(
                      (po) => po.total_daily_grams < ENV.TWO_SHIPPINGS_THRESHOLD
                    )
                  ) {
                    return null;
                  } else {
                    return (
                      <Button
                        key={action.caption}
                        size={"lg"}
                        w={"full"}
                        h={"fit-content"}
                        py={4}
                        leftIcon={
                          <Icon
                            as={action.icon}
                            strokeWidth={2}
                            h={6}
                            w={6}
                            color={"gray.600"}
                          />
                        }
                        iconSpacing={4}
                        justifyContent={"flex-start"}
                        isDisabled={action.isDisabled || !user}
                        onClick={action.trigger}
                      >
                        <VStack
                          justifyContent={"center"}
                          alignItems={"flex-start"}
                          w={"full"}
                          spacing={1}
                        >
                          <Text fontWeight={"semibold"}>{action.caption}</Text>
                          <Text
                            fontSize={14}
                            color={"gray.600"}
                            textAlign={"left"}
                            whiteSpace="normal"
                          >
                            {action.description}
                          </Text>
                        </VStack>
                      </Button>
                    );
                  }
                })}
              </VStack>
            </Section>
          </GridItem>
        </Grid>
      </VStack>
      <DeliveriesPostponeModal
        isOpen={isDeliveriesPostponeModalOpen}
        onOpen={onDeliveriesPostponeModalOpen}
        onClose={onDeliveriesPostponeModalClose}
      />
      <DeliveriesAddressModal
        isOpen={isDeliveriesAddressModalOpen}
        onOpen={onDeliveriesAddressModalOpen}
        onClose={onDeliveriesAddressModalClose}
        currentAddress={{
          firstName: (user as IUserExpanded)!.auth0.given_name!,
          lastName: (user as IUserExpanded)!.auth0.family_name!,
          address: (user as IUserExpanded)!.stripe.address!,
        }}
      />
      <DeliveriesPauseModal
        isOpen={isDeliveriesPauseOpen}
        onOpen={onDeliveriesPauseOpen}
        onClose={onDeliveriesPauseClose}
      />
      <DeliveriesFrequencyModal
        isOpen={isDeliveriesFrequencyOpen}
        onOpen={onDeliveriesFrequencyOpen}
        onClose={onDeliveriesFrequencyClose}
      />
    </>
  );
};

export default Deliveries;

const DeliveriesPostponeModal: React.FC<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}> = ({ isOpen, onOpen, onClose }) => {
  // const toast = useToast();

  // const { user, getUser } = useContext(UserContext);

  // const [postponeLoading, setPostponeLoading] = useState<boolean>(false);
  // const [newPlanDate, setNewPlanDate] = useState<number>();

  // const postponeHanlder = async (newDate: number | undefined) => {
  //   if (newDate === undefined) return;

  //   setPostponeLoading(true);

  //   stripeService
  //     .postpone_subscription({
  //       customer_id: (user as IUserExpanded).stripe.id,
  //       new_date: newDate,
  //     })
  //     .then(() => {
  //       setNewPlanDate(newDate);
  //       getUser();

  //       onClose();

  //       toast({
  //         title: "Se ha modificado la fecha tu suscripción",
  //         status: "success",
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     })
  //     .catch((err) => {
  //       console.error(err);

  //       toast({
  //         title:
  //           "¡Oh no! Algo ha ido mal, por favor inténtalo de nuevo más tarde",
  //         status: "error",
  //         duration: 9000,
  //         isClosable: true,
  //       });
  //     })
  //     .finally(() => setPostponeLoading(false));
  // };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"2xl"}>
        <ModalOverlay />
        <ModalContent borderRadius={"xl"} padding={4}>
          <ModalHeader>Aplazar las entregas</ModalHeader>
          <ModalCloseButton top={8} right={8} />
          <ModalBody>
            <Alert status="warning" borderRadius={"2xl"} my={4}>
              <AlertIcon alignSelf={"flex-start"} />

              <VStack
                spacing={0}
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
              >
                <Text fontWeight={"bold"}>
                  Contáctanos para coordinar la nueva fecha.
                </Text>
                <Text>
                  Para aplazar las entregas, contacta con nosotros en{" "}
                  <a
                    href={`mailto:ayuda@candidogmenu.com?subject=Aplazar las entregas&body=¡Hola!\n Me gustaría aplazar las entregas para mi peludo ________ al día __________. \n¡Saludos!`}
                    style={{ textDecoration: "underline" }}
                  >
                    ayuda@candidogmenu.com
                  </a>
                  .
                </Text>
              </VStack>
            </Alert>

            {/* <Text my={4}>
              Al realizar esta acción, se enviarán los pedidos que ya estén en
              curso, pero se aplazarán los siguientes, modificando también la
              fecha del pago.
            </Text>
            <VStack>
              <Text my={4}>Los envíos que se modificarán son:</Text>
              <></>
              <></>
            </VStack>
            <Text my={4}>¿A que fecha quieres aplazarlo? </Text>
            <Input
              placeholder="Fecha"
              size="md"
              type="date"
              min={moment.unix(newPlanDate!).format("YYYY-MM-DD")}
              value={moment.unix(newPlanDate!).format("YYYY-MM-DD")}
              onChange={(e) => setNewPlanDate(moment(e.target.value).unix())}
            /> */}
          </ModalBody>
          {/* <ModalFooter>
            <Button
              size={"md"}
              borderRadius={"full"}
              color={"white"}
              colorScheme={"secondary"}
              rightIcon={<Icon as={HiChevronRight} ml={10} />}
              justifyContent={"space-between"}
              isLoading={postponeLoading}
              isDisabled={!newPlanDate}
              onClick={() => postponeHanlder(newPlanDate)}
            >
              Guardar
            </Button>
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

const DeliveriesAddressModal: React.FC<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  currentAddress: {
    firstName: string;
    lastName: string;
    address: Stripe.Address;
  };
}> = ({ isOpen, onOpen, onClose, currentAddress }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"2xl"}>
        <ModalOverlay />
        <ModalContent borderRadius={"xl"} padding={4}>
          <ModalHeader>Cambio de dirección</ModalHeader>
          <ModalCloseButton top={8} right={8} />
          <ModalBody>
            <EditAddress
              currentAddress={{
                firstName: currentAddress.firstName,
                lastName: currentAddress.lastName,
                address: currentAddress.address,
              }}
              onAddressChange={() => onClose()}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeliveriesPauseModal: React.FC<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}> = ({ isOpen, onOpen, onClose }) => {
  const toast = useToast();

  const { getUser } = useContext(UserContext);

  const [reason, setReason] = useState<string>("");
  const [pauseLoading, setPauseLoading] = useState<boolean>(false);

  const handlePauseChange = async (reason: string) => {
    setPauseLoading(true);

    userService
      .pause_subscription({
        reason: reason,
      })
      .then(() => {
        setReason("");
        getUser();
        onClose();

        toast({
          title: "Se ha pausado tu suscripción",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);

        toast({
          title:
            "¡Oh no! Algo ha ido mal, por favor inténtalo de nuevo más tarde",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setPauseLoading(false));
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"2xl"}>
        <ModalOverlay />
        <ModalContent borderRadius={"xl"} padding={4}>
          <ModalHeader></ModalHeader>
          <ModalCloseButton top={8} right={8} />
          <ModalBody>
            <Heading as={"h2"}>¡Lo sentimos mucho!</Heading>
            <Text my={4}>
              Al realizar esta acción, se enviarán los pedidos que ya estén en
              curso, pero no recibirás más comida para tus peludos ni se
              realizarán más cargos en tu tarjeta hasta que decidas reanudar tu
              suscripción.
            </Text>
            <Text my={4}>¿Cuál es el motivo? </Text>
            <RadioGroup onChange={(e) => setReason(e)}>
              <VStack alignItems={"flex-start"}>
                {ENV.CANCEL_REASONS.map((reason) => (
                  <Radio
                    key={reason}
                    value={reason}
                    size={"lg"}
                    alignItems={"flex-start"}
                  >
                    {reason}
                  </Radio>
                ))}
              </VStack>
            </RadioGroup>
          </ModalBody>
          <ModalFooter>
            <Button
              size={"md"}
              borderRadius={"full"}
              color={"white"}
              colorScheme={"secondary"}
              rightIcon={<Icon as={HiChevronRight} ml={10} />}
              justifyContent={"space-between"}
              isLoading={pauseLoading}
              isDisabled={reason.length === 0}
              onClick={() => handlePauseChange(reason)}
            >
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

const DeliveriesFrequencyModal: React.FC<{
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}> = ({ isOpen, onOpen, onClose }) => {
  const toast = useToast();

  const { petOrders } = useContext(UserContext);

  const [frequencies, setFrequencies] = useState<
    {
      pet_order: string;
      frequency: 1 | 2;
    }[]
  >([]);
  const [frequenciesChangeLoading, setFrequenciesChangeLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setFrequencies(
      petOrders.map((pet_order) => ({
        pet_order: pet_order._id,
        frequency: pet_order.deliveries as 1 | 2,
      }))
    );
  }, [petOrders]);

  const handleFrequencyChange = async (
    frequencies: {
      pet_order: string;
      frequency: 1 | 2;
    }[]
  ) => {
    setFrequenciesChangeLoading(true);

    const freq_change_promises = [];
    for (let pet_order of frequencies) {
      freq_change_promises.push(
        petOrdersService.update_deliveries_frequency({
          pet_order: pet_order.pet_order,
          frequency: Number(pet_order.frequency) as 1 | 2,
        })
      );
    }

    Promise.allSettled(freq_change_promises)
      .then(() => {
        onClose();
        toast({
          title: "Se ha modificado la frecuencia de envíos",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);

        toast({
          title:
            "¡Oh no! Algo ha ido mal, por favor inténtalo de nuevo más tarde",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setFrequenciesChangeLoading(false));
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered={true} size={"2xl"}>
        <ModalOverlay />
        <ModalContent borderRadius={"xl"} padding={4}>
          <ModalHeader>Frecuencia de entrega</ModalHeader>
          <ModalCloseButton top={8} right={8} />
          <ModalBody>
            <Text mb={4}>
              ¿Sin sitio en la nevera? Modifica la frecuencia de los envíos cada
              2 o 4 semanas ¡sin cambios en el coste! Este cambio se tendrá en
              cuenta en la próxima renovación de tu plan.
            </Text>
            <Wrap spacing={6} mb={4} width={"full"}>
              {petOrders.map((pet_order) => {
                if (pet_order.total_daily_grams < ENV.TWO_SHIPPINGS_THRESHOLD) {
                  return null;
                } else {
                  return (
                    <WrapItem key={pet_order._id} width={"full"}>
                      <VStack
                        justifyContent={"flex-start"}
                        alignItems={"flex-start"}
                        width={"full"}
                      >
                        <Text fontWeight={"semibold"} fontSize={"lg"}>
                          Envíos de {(pet_order.pet as IPet).name}
                        </Text>
                        <RadioButtonGroup
                          name={"activity-level"}
                          value={
                            frequencies
                              .find((f) => f.pet_order === pet_order._id)
                              ?.frequency.toString() as "1" | "2"
                          }
                          childrenStyle={{
                            flex: { base: "unset", md: 1 },
                            width: { base: "full", md: "unset" },
                          }}
                          groupStyle={{ width: "full" }}
                          options={[
                            { value: "1", caption: "1 envío (cada 4 semanas)" },
                            {
                              value: "2",
                              caption: "2 envíos (cada 2 semanas)",
                            },
                          ]}
                          onChange={(value) => {
                            const newFreqs = [...frequencies];
                            const index = newFreqs.findIndex(
                              (f) => f.pet_order === pet_order._id
                            );

                            newFreqs[index].frequency = Number(value) as 1 | 2;
                            setFrequencies(newFreqs);
                          }}
                        />
                      </VStack>
                    </WrapItem>
                  );
                }
              })}
            </Wrap>
          </ModalBody>
          <ModalFooter>
            <Button
              size={"md"}
              borderRadius={"full"}
              color={"white"}
              colorScheme={"secondary"}
              rightIcon={<Icon as={HiChevronRight} ml={10} />}
              justifyContent={"space-between"}
              isLoading={frequenciesChangeLoading}
              isDisabled={frequencies.length === 0}
              onClick={() => handleFrequencyChange(frequencies)}
            >
              Guardar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
