import {
  Button,
  Grid,
  GridItem,
  HStack,
  Icon,
  Skeleton,
  VStack,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";

import { Elements } from "@stripe/react-stripe-js";
import { Helmet } from "react-helmet";
import { HiOutlineArrowRightOnRectangle } from "react-icons/hi2";
import { stripePromise } from "..";
import { UserContext } from "../App";
import PageHeader from "../components/PageHeader";
import ProfileAddressData from "../components/ProfileAddressData";
import ProfilePaymentData from "../components/ProfilePaymentData";
import ProfileUserData from "../components/ProfileUserData";
import { StripeService } from "../services/stripe.service";

const stripeService = new StripeService();

const Profile: React.FC = () => {
  const { user, setUser } = useContext(UserContext);

  const [stripeClientSecret, setStripeClientSecret] = useState<
    string | undefined
  >();

  useEffect(() => {
    getStripeClientSecret();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getStripeClientSecret = async () => {
    if (user) {
      stripeService
        .create_setup_intent(user.stripe.id)
        .then(({ data }) => setStripeClientSecret(data.client_secret))
        .catch((err) => {
          console.error(err);
        });
    }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Perfil - Candi Dog Menu</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <VStack
        w={"full"}
        spacing={8}
        alignItems={"start"}
        justifyContent={"start"}
        textAlign={"start"}
      >
        {user && stripeClientSecret ? (
          <>
            <PageHeader title={"Mi perfil"} />
            <Grid
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(12, 1fr)",
              }}
              gap={4}
              w={"full"}
            >
              <GridItem colSpan={{ base: 12, md: 6 }}>
                <VStack spacing={4}>
                  <ProfileUserData />
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: stripeClientSecret,
                      loader: "always",
                      locale: "es",
                      appearance: {
                        variables: {
                          colorPrimary: "#FED144",
                          borderRadius: "16px",
                        },
                      },
                    }}
                  >
                    <ProfilePaymentData />
                  </Elements>
                </VStack>
              </GridItem>
              <GridItem colSpan={{ base: 12, md: 6 }}>
                <ProfileAddressData />
              </GridItem>
            </Grid>
            <HStack justifyContent={"end"} w={"full"} p={4}>
              <Button
                borderRadius={"full"}
                fontSize={"sm"}
                fontWeight={"medium"}
                bgColor={"white"}
                borderColor={"red.500"}
                borderWidth={2}
                color={"red.500"}
                size={"md"}
                _hover={{
                  bgColor: "red.100",
                }}
                rightIcon={
                  <Icon
                    as={HiOutlineArrowRightOnRectangle}
                    strokeWidth={2}
                    h={4}
                    w={4}
                  />
                }
                onClick={() => {
                  localStorage.removeItem("jwt");
                  window.dispatchEvent(new Event("storage"));
                  setUser(false);
                }}
              >
                Cerrar sesión
              </Button>
            </HStack>
          </>
        ) : (
          <>
            <Skeleton height="20px" />
            <Skeleton height="20px" />
            <Skeleton height="20px" />
          </>
        )}
      </VStack>
    </>
  );
};

export default Profile;
