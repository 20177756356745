import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  List,
  ListIcon,
  ListItem,
  VStack,
} from "@chakra-ui/react";
import { HiChevronRight, HiXMark } from "react-icons/hi2";

import React from "react";
import Section from "../containers/Section";
import { useProfileUserData } from "../hooks/useProfileUserData";

const ProfileUserData: React.FC = () => {
  const {
    userInputs,
    setUserInputs,
    updateUserLoading,
    validStep,
    errorMsgs,
    updateUser,
  } = useProfileUserData();

  return (
    <Section title="Información personal">
      <VStack
        as={"form"}
        align={"flex-start"}
        w={"full"}
        spacing={6}
        onSubmit={(e) => {
          e.preventDefault();
          console.log("e.target", e.target);

          updateUser(userInputs.email, userInputs.phone_number);
        }}
      >
        <VStack
          spacing={4}
          alignItems={"start"}
          justifyContent={"start"}
          w={"full"}
        >
          <FormControl id="email" isRequired>
            <FormLabel fontSize={"sm"}>Correo electrónico</FormLabel>
            <Input
              type="email"
              value={userInputs.email}
              bg={"white"}
              onChange={(e) =>
                setUserInputs({ ...userInputs, email: e.target.value })
              }
            />
          </FormControl>
          <FormControl id="phone" isRequired>
            <FormLabel fontSize={"sm"}>Teléfono</FormLabel>
            <InputGroup>
              <InputLeftAddon children={"+34"} />
              <Input
                type="tel"
                value={userInputs.phone_number}
                bg={"white"}
                onChange={(e) =>
                  setUserInputs({ ...userInputs, phone_number: e.target.value })
                }
              />
            </InputGroup>
          </FormControl>
        </VStack>
        <Collapse in={errorMsgs.length > 0}>
          <List spacing={3}>
            {errorMsgs.map((error) => (
              <ListItem key={error} alignItems={"center"} color={"red.500"}>
                <ListIcon as={HiXMark} strokeWidth={1} h={5} w={5} />
                {error}
              </ListItem>
            ))}
          </List>
        </Collapse>

        <Button
          size={"md"}
          borderRadius={"full"}
          color={"white"}
          colorScheme={"secondary"}
          rightIcon={<Icon as={HiChevronRight} ml={10} />}
          justifyContent={"space-between"}
          alignSelf={"flex-end"}
          isLoading={updateUserLoading}
          isDisabled={!validStep}
          type="submit"
        >
          Guardar
        </Button>
      </VStack>
    </Section>
  );
};

export default ProfileUserData;
