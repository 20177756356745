import { useContext, useEffect, useState } from "react";

import { useToast } from "@chakra-ui/react";
import phone from "phone";
import Stripe from "stripe";
import { UserContext } from "../App";
import { validateEmail } from "../constants/utils";
import { UserService } from "../services/user.service";

const userService = new UserService();

export const useProfileUserData = () => {
  const toast = useToast();

  const { user, getUser } = useContext(UserContext);

  const [userInputs, setUserInputs] = useState<{
    email: string;
    phone_number: string;
  }>({
    email: "",
    phone_number: "",
  });
  const [updateUserLoading, setUpdateUserLoading] = useState<boolean>(false);
  const [validStep, setValidStep] = useState(false);
  const [errorMsgs, setErrorMsgs] = useState<Array<string>>([]);

  useEffect(() => {
    if (user) {
      setUserInputs({
        email: (user.stripe as Stripe.Customer).email || "",
        phone_number:
          ((user.stripe as Stripe.Customer).phone?.length! > 9
            ? (user.stripe as Stripe.Customer).phone?.slice(3)
            : (user.stripe as Stripe.Customer).phone) || "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (user && userInputs.email !== "" && userInputs.phone_number !== "") {
      validateStep();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInputs]);

  const validateStep = () => {
    const valid_email =
      userInputs.email !== "" && validateEmail(userInputs.email);
    const valid_phone =
      userInputs.phone_number !== "" &&
      phone(userInputs.phone_number, { country: "ES" }).isValid;

    let new_error_msgs = [];
    if (!valid_email) {
      new_error_msgs.push("Introduce un email válido");
    }
    if (!valid_phone) {
      new_error_msgs.push("Introduce un teléfono válido");
    }
    setErrorMsgs(new_error_msgs);

    const valid = valid_email && valid_phone;
    setValidStep(valid);
  };

  const updateUser = async (email?: string, phone_number?: string) => {
    setUpdateUserLoading(true);

    userService
      .update({
        email,
        phone_number: `+34${phone_number}`,
      })
      .then(() => {
        getUser();

        toast({
          title: "Se han modificado los datos correctamente",
          status: "success",
          duration: 9000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error(err);

        toast({
          title: "Ha ocurrido un error. Por favor inténtalo de nuevo.",
          description: `${err}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => {
        setUpdateUserLoading(false);
      });
  };

  return {
    userInputs,
    setUserInputs,
    updateUserLoading,
    validStep,
    errorMsgs,
    updateUser,
  };
};
