import { Skeleton, VStack } from "@chakra-ui/react";

import React from "react";
import Section from "../containers/Section";
import { useProfileAddressData } from "../hooks/useProfileAddressData";
import EditAddress from "./EditAddress";

const ProfileAddressData: React.FC = () => {
  const {
    firstName,
    lastName,
    stripeCustomerAddress,
    stripeCustomerAddressLoading,
  } = useProfileAddressData();

  return (
    <Section title="Dirección postal">
      <VStack align={"flex-start"} w={"full"} spacing={6}>
        {stripeCustomerAddress ? (
          <Skeleton
            isLoaded={!stripeCustomerAddressLoading}
            as={VStack}
            spacing={6}
            align={"flex-start"}
          >
            <EditAddress
              currentAddress={{
                firstName,
                lastName,
                address: stripeCustomerAddress,
              }}
            />
          </Skeleton>
        ) : (
          <> </>
        )}
      </VStack>
    </Section>
  );
};

export default ProfileAddressData;
