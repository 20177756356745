import axios, { AxiosResponse } from "axios";

import Auth0 from "auth0";
import { ENV } from "../constants/environment.base";

export class AuthService {
  private readonly API_ROUTE_PREFIX: string = "auth";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ENDPOINT;

  /**
   * Login customer
   *
   * @param email User email address
   * @param password User password
   *
   * @returns Promise with customer login response
   */
  public login: (
    email: string,
    password: string
  ) => Promise<AxiosResponse<Auth0.TokenResponse>> = (email, password) => {
    return axios.post(`${this.API_PREFIX}/login`, { email, password });
  };

  /**
   * Request Reset password
   *
   * @param email User email address
   *
   * @returns Promise with reset password response
   */
  public requestResetPassword: (
    email: string
  ) => Promise<AxiosResponse<any, any>> = (email) => {
    return axios.get(`${this.API_PREFIX}/reset-password`, {
      params: { email },
    });
  };
}
