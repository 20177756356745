export const ERROR_MESSAGES: { [key: string]: string } = {
  ERR_USER_HAS_ACTIVE_PLAN: "Ese usuario ya tiene un plan activo.",
  ERR_ONBOARD_INITIAL_SIGNUP:
    "Ha ocurrido un error en el registro inicial de tu usuario",
  ERR_AUTH0_NO_USER_FOUND:
    "No se ha encontrado ninguna cuenta con esa dirección de correo. Prueba con otra dirección de correo, ¡o regístrate!",
  ERR_USER_NOT_COMPLETE_PROFILE:
    "No tienes un plan asociado a tu cuenta. Si deseas crearlo, completa el proceso de registro",
  ERR_AUTH0_PASSWORD_SIGN_IN: "Las credenciales son incorrectas",
};
