import { useDisclosure, useToast } from "@chakra-ui/react";
import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";

import Stripe from "stripe";
import { UserContext } from "../App";
import { StripeService } from "../services/stripe.service";
import { IUserExpanded } from "../types/types";

const stripeService = new StripeService();

export const useProfilePaymentData = () => {
  const { user } = useContext(UserContext);

  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const { isOpen, onToggle } = useDisclosure();

  const [stripeErrorMessage, setStripeErrorMessage] = useState<
    string | undefined
  >();
  const [userPaymentMethods, setUserPaymentMethods] = useState<
    Stripe.PaymentMethod[] | undefined
  >();
  const [userPaymentMethodsLoading, setUserPaymentMethodsLoading] =
    useState<boolean>(false);
  const [
    stripePaymentMethodSubmitLoading,
    setStripePaymentMethodSubmitLoading,
  ] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      getUserPaymentMethods(user.stripe.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getUserPaymentMethods = async (customer: string) => {
    setUserPaymentMethodsLoading(true);

    await stripeService
      .list_payment_methods(customer)
      .then(({ data }) => setUserPaymentMethods(data.payment_methods))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setUserPaymentMethodsLoading(false);
      });
  };

  const handleStripePaymentMethodSubmit = async (
    event: React.FormEvent<HTMLDivElement>
  ) => {
    console.log("event.currentTarget", event.currentTarget);

    event.preventDefault();
    setStripePaymentMethodSubmitLoading(true);

    if (!stripe || !elements) {
      return null;
    }

    await stripe
      .confirmSetup({
        elements,
        redirect: "if_required",
        confirmParams: {
          return_url: "http://localhost:3000/profile",
          payment_method_data: {
            billing_details: {
              name: (user as IUserExpanded).stripe.name as string,
              email: (user as IUserExpanded).auth0.email as string,
              phone: (user as IUserExpanded).stripe.phone as string,
              address: {
                line1: (user as IUserExpanded).stripe.address?.line1 || "",
                line2: (user as IUserExpanded).stripe.address?.line2 || "",
                city: (user as IUserExpanded).stripe.address?.city || "",
                state: (user as IUserExpanded).stripe.address?.state || "",
                postal_code:
                  (user as IUserExpanded).stripe.address?.postal_code || "",
                country: "ES",
              },
            },
          },
        },
      })
      .then(({ error, setupIntent }) => {
        if (error) {
          setStripeErrorMessage(error.message);
        }

        if (!setupIntent || !setupIntent.client_secret) {
          console.error("No setup intent client secret found");
          setStripeErrorMessage("No se ha podido crear el método de pago");
          return;
        }

        handlePaymentMethodCreateResponse(setupIntent.client_secret);
      })
      .finally(() => {
        onToggle();
        setStripePaymentMethodSubmitLoading(false);
      });
  };

  const handlePaymentMethodCreateResponse: (
    setupIntentClientSecret: string
  ) => void = (setupIntentClientSecret) => {
    if (!stripe) return;
    if (!setupIntentClientSecret) return;

    stripe
      .retrieveSetupIntent(setupIntentClientSecret)
      .then(({ setupIntent }) => {
        if (user) {
          getUserPaymentMethods(user.stripe.id);
        }

        switch (setupIntent?.status) {
          case "succeeded":
            toast({
              title: "Tarjeta añadida",
              description: "¡Enhorabuena! Se ha guardado el método de pago",
              status: "success",
              duration: 9000,
              isClosable: true,
            });

            break;

          case "processing":
            toast({
              title: "En proceso...",
              description: "Se están procesando los datos introducidos",
              status: "loading",
              duration: 9000,
              isClosable: true,
            });

            break;

          case "requires_payment_method":
            toast({
              title: "Error",
              description:
                "Ha ocurrido un error. Por favor inténtalo de nuevo o prueba con otra tarjeta",
              status: "error",
              duration: 9000,
              isClosable: true,
            });

            break;

          default:
            break;
        }
      });
  };

  return {
    userPaymentMethods,
    userPaymentMethodsLoading,
    onToggle,
    isOpen,
    handleStripePaymentMethodSubmit,
    stripePaymentMethodSubmitLoading,
    stripeErrorMessage,
  };
};
