import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputLeftAddon,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import { HiChevronRight, HiXMark } from "react-icons/hi2";

import { useContext } from "react";
import useOnboardingStep5 from "../hooks/useOnboardingStep5";
import { OnboardingContext } from "../pages/Onboarding";

const HEADING = { singular: "¡Tu turno!", plural: "¡Tu turno!" };

const OnboardingStep5 = () => {
  const {
    data,
    setData,
    errorMsgs,
    onboardingSubmitLoading,
    handleOnboardingStepsSubmit,
  } = useContext(OnboardingContext);

  const { validStep } = useOnboardingStep5({ data });

  return (
    <VStack
      alignItems={"center"}
      spacing={{ base: 6, md: 12 }}
      w={"full"}
      maxW={"container.sm"}
    >
      <Heading as={"h1"} fontWeight="bold" fontSize={{ base: 25, md: 40 }}>
        {data.n_of_pets! > 1 ? HEADING.plural : HEADING.singular}
      </Heading>
      <Text
        fontWeight="medium"
        fontSize={{ base: 13, md: 15 }}
        textAlign={"center"}
        color={"gray.500"}
      >
        {data.n_of_pets! > 1
          ? "¡Ya tenemos los menús adecuados para tus peludos! Solo queda que nos proporciones unos datos para ponerlos a tu disposición."
          : "¡Ya tenemos el menú adecuado para tu peludo! Solo queda que nos proporciones unos datos para ponerlo a tu disposición."}
      </Text>
      <VStack alignItems={"center"} spacing={{ base: 2, md: 4 }} w={"full"}>
        <FormControl id="email" isRequired>
          <FormLabel fontWeight={"medium"}>Correo electrónico</FormLabel>
          <Input
            type="email"
            placeholder="correo@dominio.com"
            size={"lg"}
            value={data.user.user_email || ""}
            onChange={(e) => {
              setData({
                ...data,
                user: { ...data.user, user_email: e.target.value },
              });
            }}
          />
        </FormControl>
        <FormControl id="phone_number" isRequired>
          <FormLabel fontWeight={"medium"} h={"full"}>
            Móvil
          </FormLabel>
          <InputGroup size={"lg"} borderRadius={"xl"}>
            <InputLeftAddon children="+34" />
            <Input
              type="tel"
              placeholder="666 666 666"
              value={data.user.user_phone_number || ""}
              onChange={(e) => {
                setData({
                  ...data,
                  user: { ...data.user, user_phone_number: e.target.value },
                });
              }}
            />
          </InputGroup>
        </FormControl>
      </VStack>
      <Collapse in={errorMsgs.length > 0}>
        <List spacing={3}>
          {errorMsgs.map((error) => (
            <ListItem key={error} alignItems={"center"} color={"red.500"}>
              <ListIcon as={HiXMark} strokeWidth={1} h={5} w={5} />
              {error}
            </ListItem>
          ))}
        </List>
      </Collapse>

      <Button
        size={"lg"}
        borderRadius={"full"}
        color={"white"}
        colorScheme="brand"
        type="submit"
        rightIcon={<Icon as={HiChevronRight} ml={10} />}
        justifyContent={"space-between"}
        isDisabled={!validStep}
        isLoading={onboardingSubmitLoading}
        loadingText="¡Terminando!"
        onClick={() => handleOnboardingStepsSubmit()}
      >
        Continuar
      </Button>
    </VStack>
  );
};

export default OnboardingStep5;
