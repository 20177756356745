import {
  Button,
  Flex,
  HStack,
  Heading,
  Highlight,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import {
  HiOutlineChatBubbleOvalLeftEllipsis,
  HiOutlinePencil,
} from "react-icons/hi2";

import { TbDog } from "react-icons/tb";
import { OnboardingContext } from "../pages/Onboarding";

const SUBHEADING_FEATURES = [
  {
    icon: HiOutlinePencil,
    text: "Rellena este mini-formulario",
    accentColor: "green.200",
  },
  {
    icon: HiOutlineChatBubbleOvalLeftEllipsis,
    text: "Recibe el plan personalizado",
    accentColor: "brand.200",
  },
  {
    icon: TbDog,
    text: "¡Solo queda esperar la comida!",
    accentColor: "blue.200",
  },
];

const HEADING = {
  singular: "¡Transforma la alimentación de tu peludo!",
  plural: "¡Transforma la alimentación de tus peludos!",
};

const OnboardingStep1 = () => {
  const {  goNextStep, data, setData } = useContext(OnboardingContext);

  const [invalidStep, setInvalidStep] = useState(false);

  return (
    <VStack alignItems={"center"} spacing={{ base: 6, md: 12 }} w={"full"}>
      <VStack alignItems={"center"} spacing={8}>
        <Heading
          as={"h1"}
          fontWeight={"800"}
          fontSize={{ base: 25, md: 40 }}
          textAlign={"center"}
        >
          <Highlight
            query="Transforma"
            styles={{
              px: "2",
              py: "1",
              rounded: "3xl",
              bg: "brand.200",
            }}
          >
            {data.n_of_pets! > 1 ? HEADING.plural : HEADING.singular}
          </Highlight>
        </Heading>
        <SimpleGrid spacing={{ base: 3, md: 2 }} columns={{ base: 1, md: 3 }}>
          {SUBHEADING_FEATURES.map((feature, index) => (
            <HStack key={index} alignItems={"center"}>
              <Flex
                w={8}
                h={8}
                align={"center"}
                justify={"center"}
                rounded={"full"}
                bg={feature.accentColor}
              >
                <Icon as={feature.icon} />
              </Flex>
              <Text fontWeight={500} fontSize={{ base: 14, md: 16 }}>
                {feature.text}
              </Text>
            </HStack>
          ))}
        </SimpleGrid>
      </VStack>
      <Text fontWeight="semibold" fontSize={{ base: 20, md: 30 }}>
        ¿Cuantos peludos tienes?
      </Text>

      <SimpleGrid spacing={2} columns={{ base: 2, md: 4 }}>
        {["1", "2", "3", "+"].map((number, index) => (
          <Button
            key={number}
            size="lg"
            variant={"outline"}
            height={"100px"}
            width={"100px"}
            borderRadius={"xl"}
            border="2px"
            borderColor="brand.500"
            fontSize={"35"}
            color="brand.500"
            isActive={data.n_of_pets === index + 1}
            _active={{ bgColor: "brand.200", color: "white" }}
            isDisabled={index === 3 && data.n_of_pets === 4}
            onClick={() => {
              if (data.n_of_pets !== index + 1) {
                setData({
                  ...data,
                  n_of_pets: index + 1,
                  pets: Array(index + 1).fill({}),
                });
              }

              if (index !== 3) {
                goNextStep();
              } else {
                setInvalidStep(true);
              }
            }}
          >
            {number}
          </Button>
        ))}
      </SimpleGrid>
      {invalidStep && (
        <Text
          fontSize={14}
          textAlign={"center"}
          color={"gray.500"}
          mt={4}
          maxW={"container.sm"}
        >
          ¡Guau, menuda familia! Contacta con nosotros en{" "}
          <a
            href={`mailto:ayuda@candidogmenu.com?subject=Mis peludos necesitan atención personalizada!&body=¡Hola!\n Tengo más de 3 peludos y necesitan un menú a medida. \n¡Saludos!`}
            style={{ textDecoration: "underline" }}
          >
            ayuda@candidogmenu.com
          </a>{" "}
          para asegurarnos de hacer un menú personalizado para tus peludos.
        </Text>
      )}
    </VStack>
  );
};

export default OnboardingStep1;
