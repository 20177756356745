import { useEffect, useState } from "react";

import { phone } from "phone";
import { validateEmail } from "../constants/utils";
import { IOnboardingData } from "./useOnboarding";

const useOnboardingStep5: ({ data }: { data: IOnboardingData }) => {
  validStep: boolean;
} = ({ data }) => {
  const [validStep, setValidStep] = useState(false);

  useEffect(() => {
    validateStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateStep = () => {
    const valid =
      data.user.hasOwnProperty("user_email") &&
      data.user.hasOwnProperty("user_phone_number") &&
      validateEmail(data.user.user_email!) &&
      phone(data.user.user_phone_number!, { country: "ES" }).isValid;

    setValidStep(valid);
  };

  return {
    validStep,
  };
};

export default useOnboardingStep5;
