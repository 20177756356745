import {
  HiOutlineHome,
  HiOutlineTruck,
  HiOutlineUserCircle,
} from "react-icons/hi2";

import { IconType } from "react-icons";
import { TbDog } from "react-icons/tb";

interface IEnv {
  API_ENDPOINT: string;
  NAVBAR_LINKS: {
    caption: string;
    destination: string;
    description: string;
    accentColor: string;
    icon?: IconType;
  }[];
  STRIPE_KEY: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_DOMAIN: string;
  AUTH0_CONNECTION: string;
  AUTH0_AUDIENCE: string;
  GMAPS_API_KEY: string;
  ENCRYPTION_KEY: string;
  DELIVERY_KEY_CAPTIONS: { [key: string]: string };
  DOG_BREEDS: Array<{ code: number; name: string }>;
  HOLIDAYS: Array<{
    date: string;
    start: string;
    end: string;
    name: string;
    type: "public" | "observance";
    rule?: string;
    substitute?: boolean;
    note?: string;
  }>;
  CANCEL_REASONS: Array<string>;
  TWO_SHIPPINGS_THRESHOLD: number;
  MENUS_CAPTIONS: { [key: string]: string };
}

export const ENV: IEnv = {
  API_ENDPOINT:
    process.env.REACT_APP_API_ENDPOINT || "https://api.candidogmenu.com",
  NAVBAR_LINKS: [
    {
      caption: "Inicio",
      destination: "/",
      description: "Vista inicial de tu cuenta en Candi",
      accentColor: "brand.200",
      icon: HiOutlineHome,
    },
    {
      caption: "Envíos",
      destination: "/deliveries",
      description: "Gestiona los envíos de los menús",
      accentColor: "blue.200",
      icon: HiOutlineTruck,
    },
    {
      caption: "Peludos",
      destination: "/pets",
      description: "Tus peludos disfrutando de Candi",
      accentColor: "green.200",
      icon: TbDog,
    },
    {
      caption: "Perfil",
      destination: "/profile",
      description: "Mantén tus datos actualizados",
      accentColor: "gray.200",
      icon: HiOutlineUserCircle,
    },
  ],
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY || "",
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID || "",
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN || "",
  AUTH0_CONNECTION: process.env.REACT_APP_AUTH0_CONNECTION || "",
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE || "",
  GMAPS_API_KEY: process.env.REACT_APP_GMAPS_API_KEY || "",
  ENCRYPTION_KEY: process.env.REACT_APP_ENCRYPTION_KEY || "",
  DELIVERY_KEY_CAPTIONS: {
    pending: "Pendiente",
    preparing: "Pendiente",
    packaged: "Pendiente",
    cancelled: "Cancelado",
    delivered: "Entregado",
  },
  MENUS_CAPTIONS: {
    beef: "Ternera",
    chicken: "Pollo",
    // fish: "Pescado",
    // pig: "Cerdo",
  },
  DOG_BREEDS: [
    { code: 1, name: "Mestizo" },
    { code: 2, name: "Otras razas" },
    { code: 3, name: "Afador" },
    { code: 4, name: "Affenpinscher" },
    { code: 5, name: "Akita Americano" },
    { code: 6, name: "Akita Inu" },
    { code: 7, name: "Alaskan Husky" },
    { code: 8, name: "Alaskan Klee Kai" },
    { code: 9, name: "Alaskan Malamute" },
    { code: 10, name: "American Bulldog" },
    { code: 11, name: "American Foxhound" },
    { code: 12, name: "American Staffordshire Terrier" },
    { code: 13, name: "Azawakh" },
    { code: 14, name: "Barbet" },
    { code: 15, name: "Basenji" },
    { code: 16, name: "Basset Hound" },
    { code: 17, name: "Beagle" },
    { code: 18, name: "Beagle Harrier" },
    { code: 19, name: "Beauceron" },
    { code: 20, name: "Berger Picard" },
    { code: 21, name: "Bichón Boloñés" },
    { code: 22, name: "Bichón Frisé" },
    { code: 23, name: "Bichón Habanero" },
    { code: 24, name: "Bichón Maltés" },
    { code: 25, name: "Black and Tan Coonhound" },
    { code: 26, name: "Bloodhound" },
    { code: 27, name: "Bodeguero andaluz" },
    { code: 28, name: "Boerboel" },
    { code: 29, name: "Border Collie" },
    { code: 30, name: "Border Terrier" },
    { code: 31, name: "Borzoi" },
    { code: 32, name: "Boxer" },
    { code: 33, name: "Boyero de Berna" },
    { code: 34, name: "Braco Alemán de Pelo Corto" },
    { code: 35, name: "Braco Alemán de pelo Duro" },
    { code: 36, name: "Braco de Weimar" },
    { code: 37, name: "Braco Húngaro" },
    { code: 38, name: "Braco Italiano" },
    { code: 39, name: "Buhund Noruego" },
    { code: 40, name: "Bull Terrier" },
    { code: 41, name: "Bull Terrier Miniatura" },
    { code: 42, name: "Bulldog Francés" },
    { code: 43, name: "Bulldog Inglés" },
    { code: 44, name: "Bullmastiff" },
    { code: 45, name: "Cane Corso" },
    { code: 46, name: "Caniche Mediano" },
    { code: 47, name: "Caniche Miniatura" },
    { code: 48, name: "Catahoula Leopard Dog" },
    { code: 49, name: "Cavalier King Charles Spaniel" },
    { code: 50, name: "Chihuahua" },
    { code: 51, name: "Chin Japonés" },
    { code: 52, name: "Chinook" },
    { code: 53, name: "Chow Chow" },
    { code: 54, name: "Clumber Spaniel" },
    { code: 55, name: "Cocker Spaniel Americano" },
    { code: 56, name: "Cocker Spaniel Inglés" },
    { code: 57, name: "Collie" },
    { code: 58, name: "Collie barbudo o bearded collie" },
    { code: 59, name: "Corgi Galés de Cardigan" },
    { code: 60, name: "Corgi Galés de Pembroke" },
    { code: 61, name: "Cotón de Tuléar" },
    { code: 62, name: "Crestado chino" },
    { code: 63, name: "Curly-Coated Retriever" },
    { code: 64, name: "Dachshund" },
    { code: 65, name: "Dálmata" },
    { code: 66, name: "Dingo Australiano" },
    { code: 67, name: "Dóberman" },
    { code: 68, name: "Dogo Alemán" },
    { code: 69, name: "Dogo Argentino" },
    { code: 70, name: "Dogo de Burdeos" },
    { code: 71, name: "Dutch Shepherd" },
    { code: 72, name: "Elkhound Noruego" },
    { code: 73, name: "Field Spaniel" },
    { code: 74, name: "Finnish Spitz" },
    { code: 75, name: "Fox Terrier" },
    { code: 76, name: "Fox Terrier Toy" },
    { code: 77, name: "Foxhound Inglés" },
    { code: 78, name: "Galgo Afgano" },
    { code: 79, name: "Galgo Español" },
    { code: 80, name: "Galgo Italiano" },
    { code: 81, name: "Golden Retriever" },
    { code: 82, name: "Gran Boyero Suizo" },
    { code: 83, name: "Gran Danés" },
    { code: 84, name: "Grifón de Bruselas" },
    { code: 85, name: "Grifón vandeano basset pequeño" },
    { code: 86, name: "Hamiltonstovare" },
    { code: 87, name: "Harrier" },
    { code: 88, name: "Hokkaido Inu" },
    { code: 89, name: "Husky Siberiano" },
    { code: 90, name: "Japanese Spitz" },
    { code: 91, name: "Karelian Bear" },
    { code: 92, name: "Keeshond" },
    { code: 93, name: "Komondor" },
    { code: 94, name: "Korean Jindo" },
    { code: 95, name: "Kuvasz" },
    { code: 96, name: "Labradoodle" },
    { code: 97, name: "Labrador Retriever" },
    { code: 98, name: "Lagotto Romagnolo" },
    { code: 99, name: "Lancashire Heeler" },
    { code: 100, name: "Leonberger" },
    { code: 101, name: "Lhasa Apso" },
    { code: 102, name: "Mastín" },
    { code: 103, name: "Mastín Afgano" },
    { code: 104, name: "Mastín Español" },
    { code: 105, name: "Mastín Italiano" },
    { code: 106, name: "Mastín Napolitano" },
    { code: 107, name: "Mastín Tibetano" },
    { code: 108, name: "Mucuchíes" },
    { code: 109, name: "Mudi" },
    { code: 110, name: "Münsterländer Grande" },
    { code: 111, name: "Otterhound" },
    { code: 112, name: "Ovejero Magallánico" },
    { code: 113, name: "Papillón" },
    { code: 114, name: "Pastor Alemán" },
    { code: 115, name: "Pastor Belga" },
    { code: 116, name: "Pastor Belga Groenendael" },
    { code: 117, name: "Pastor Belga Laekenois" },
    { code: 118, name: "Pastor Belga Malinois" },
    { code: 119, name: "Pastor Belga Tervuerense" },
    { code: 120, name: "Pastor Blanco Suizo" },
    { code: 121, name: "Pastor Catalán" },
    { code: 122, name: "Pastor Chiribaya o Pastor Peruano" },
    { code: 123, name: "Pastor Croata" },
    { code: 124, name: "Pastor de Anatolia" },
    { code: 125, name: "Pastor de Briep" },
    { code: 126, name: "Pastor de Karst" },
    { code: 127, name: "Pastor de las islas Shetland" },
    { code: 128, name: "Pastor de los Pirineos" },
    { code: 129, name: "Pastor de Rusia Meridional" },
    { code: 130, name: "Pastor del Cáucaso" },
    { code: 131, name: "Pastor Ganadero Australiano" },
    { code: 132, name: "Pastor Garafiano" },
    { code: 133, name: "Pastor Islandés" },
    { code: 134, name: "Pastor Lapón" },
    { code: 135, name: "Pastor Lapón de Suecia" },
    { code: 136, name: "Pastor Leonés" },
    { code: 137, name: "Pastor Mallorquín" },
    { code: 138, name: "Pastor Ovejero Australiano" },
    { code: 139, name: "Pastor Polaco de las Llanuras" },
    { code: 140, name: "Pastor Polaco de Podhale" },
    { code: 141, name: "Pastor Portugués" },
    { code: 142, name: "Pastor Vasco" },
    { code: 143, name: "Pastor Yugoslavo de Charplanina" },
    { code: 144, name: "Pekinés" },
    { code: 145, name: "Pequeño Brabazón" },
    { code: 146, name: "Perdiguero de Burgos" },
    { code: 147, name: "Perdiguero de Dentre" },
    { code: 148, name: "Perdiguero Portugués" },
    { code: 149, name: "Perro Crestado Chino" },
    { code: 150, name: "Perro de Agua Americano" },
    { code: 151, name: "Perro de Agua Español" },
    { code: 152, name: "Perro de Agua Frisón" },
    { code: 153, name: "Perro de Aguas Portugués" },
    { code: 154, name: "Perro de Canaan" },
    { code: 155, name: "Perro de Groenlandia" },
    { code: 156, name: "Perro de Montaña de los Pirineos" },
    { code: 157, name: "Perro del Atlas" },
    { code: 158, name: "Perro Esquimal Americano" },
    { code: 159, name: "Perro Esquimal Canadiense" },
    { code: 160, name: "Perro Hiena" },
    { code: 161, name: "Perro Lobo Chescolovaco" },
    { code: 162, name: "Perro Lobo de Saarlos" },
    { code: 163, name: "Perro Lobo Herreño" },
    { code: 164, name: "Perro Majorero" },
    { code: 165, name: "Perro sin pelo Mexicano" },
    { code: 166, name: "Phalène" },
    { code: 167, name: "Pinscher" },
    { code: 168, name: "Pinscher Austriaco" },
    { code: 169, name: "Pinscher Miniatura" },
    { code: 170, name: "Pit bull terrier americano" },
    { code: 171, name: "Podenco Andaluz" },
    { code: 172, name: "Podenco Canario" },
    { code: 173, name: "Podenco Ibicenco" },
    { code: 174, name: "Podenco Portugués" },
    { code: 175, name: "Pointer" },
    { code: 176, name: "Pointer Inglés" },
    { code: 177, name: "Pomerania" },
    { code: 178, name: "Porcelain" },
    { code: 179, name: "Presa Canario" },
    { code: 180, name: "Pudelpointer" },
    { code: 181, name: "Pug o Carlino" },
    { code: 182, name: "Puli" },
    { code: 183, name: "Raad van Beheer" },
    { code: 184, name: "Rastreador Montañés de Baviera" },
    { code: 185, name: "Rastreadores de Hannover" },
    { code: 186, name: "Ratonero Bodeguero Andaluz" },
    { code: 187, name: "Ratonero de Praga" },
    { code: 188, name: "Ratonero Mallorquín" },
    { code: 189, name: "Ratonero Valenciano" },
    { code: 190, name: "Redbone Coonhound" },
    { code: 191, name: "Retriever de Bahía de Chesapeake" },
    { code: 192, name: "Retriever de Pelo Liso" },
    { code: 193, name: "Rodeshian Ridgeback" },
    { code: 194, name: "Rottweiler" },
    { code: 195, name: "Sabueso Alemán" },
    { code: 196, name: "Sabueso Artesiano" },
    { code: 197, name: "Sabueso Austriaco Negro y Fuego" },
    { code: 198, name: "Sabueso Bosnio de Pelo Cerdoso" },
    { code: 199, name: "Sabueso de Hygen" },
    { code: 200, name: "Sabueso de Montañas de Montenegro" },
    { code: 201, name: "Sabueso del Tirol" },
    { code: 202, name: "Sabueso Eslovaco" },
    { code: 203, name: "Sabueso Español" },
    { code: 204, name: "Sabueso Finlandés" },
    { code: 205, name: "Sabueso Francés Tricolor" },
    { code: 206, name: "Sabueso Halden" },
    { code: 207, name: "Sabueso Italiano de Pelo Corto" },
    { code: 208, name: "Sabueso Italiano de Pelo Duro" },
    { code: 209, name: "Sabueso Noruego" },
    { code: 210, name: "Sabueso Polaco" },
    { code: 211, name: "Sabueso Schiller" },
    { code: 212, name: "Sabueso Serbio" },
    { code: 213, name: "Sabueso Suizo" },
    { code: 214, name: "Sabueso Tinajero" },
    { code: 215, name: "Sabueso Tricolor Serbio" },
    { code: 216, name: "Saluki" },
    { code: 217, name: "Samoyedo" },
    { code: 218, name: "San Bernardo" },
    { code: 219, name: "Schapendoes Neerlandés" },
    { code: 220, name: "Schipperke" },
    { code: 221, name: "Schnauzer" },
    { code: 222, name: "Schnauzer Blanco" },
    { code: 223, name: "Schnauzer Gigante" },
    { code: 224, name: "Schnauzer Miniatura" },
    { code: 225, name: "Setter Gordon" },
    { code: 226, name: "Setter Inglés" },
    { code: 227, name: "Setter Irlandés" },
    { code: 228, name: "Setter Irlandés Rojo" },
    { code: 229, name: "Setter Irlandés Rojo y Blanco" },
    { code: 230, name: "Shar Pei" },
    { code: 231, name: "Shiba Inu" },
    { code: 232, name: "Shih Tzu" },
    { code: 233, name: "Shikoku Inu" },
    { code: 234, name: "Silky Terrier Australiano" },
    { code: 235, name: "Spaniel Bretón" },
    { code: 236, name: "Spaniel Continental Enanos" },
    { code: 237, name: "Spaniel de Agua Irlandés" },
    { code: 238, name: "Spaniel de Pont-Audemer" },
    { code: 239, name: "Spaniel de Sussex" },
    { code: 240, name: "Spaniel Francés" },
    { code: 241, name: "Spaniel japonés" },
    { code: 242, name: "Spaniel Picardo" },
    { code: 243, name: "Spitz Alemán" },
    { code: 244, name: "Spitz de Norrbotten" },
    { code: 245, name: "Spitz Japonés Enano" },
    { code: 246, name: "Spitz Lobo" },
    { code: 247, name: "Springer Spaniel Inglés" },
    { code: 248, name: "Stabyhon (Perdiguero Frisón)" },
    { code: 249, name: "Tchuvatch Eslovaco" },
    { code: 250, name: "Teckel" },
    { code: 251, name: "Tejonero Alpino" },
    { code: 252, name: "Terranova" },
    { code: 253, name: "Terrier Americano sin pelo" },
    { code: 254, name: "Terrier Australiano" },
    { code: 255, name: "Terrier Brasileño" },
    { code: 256, name: "Terrier Cazador Alemán" },
    { code: 257, name: "Terrier Checo" },
    { code: 258, name: "Terrier Dandie Dinmont" },
    { code: 259, name: "Terrier de Airedale" },
    { code: 260, name: "Terrier de Bedlington" },
    { code: 261, name: "Terrier de Boston" },
    { code: 262, name: "Terrier de Cairn" },
    { code: 263, name: "Terrier de Lakeland" },
    { code: 264, name: "Terrier de Manchester" },
    { code: 265, name: "Terrier de Norfolk" },
    { code: 266, name: "Terrier de Norwich" },
    { code: 267, name: "Terrier de Skye" },
    { code: 268, name: "Terrier Escocés" },
    { code: 269, name: "Terrier Galés" },
    { code: 270, name: "Terrier Glen de Imaal" },
    { code: 271, name: "Terrier Irlandés" },
    { code: 272, name: "Terrier Jack Russell" },
    { code: 273, name: "Terrier Japonés" },
    { code: 274, name: "Terrier Kerry Blue" },
    { code: 275, name: "Terrier Parson Russell" },
    { code: 276, name: "Terrier Rata" },
    { code: 277, name: "Terrier Ruso Negro" },
    { code: 278, name: "Terrier Sealyham" },
    { code: 279, name: "Terrier Staffordshire Bull" },
    { code: 280, name: "Terrier Tibetano" },
    { code: 281, name: "Tosa Inu" },
    { code: 282, name: "Toy Terrier Ruso" },
    { code: 283, name: "Vallhund Sueco" },
    { code: 284, name: "Vizsla" },
    { code: 285, name: "Volpino Italiano" },
    { code: 286, name: "West Highland White Terrier" },
    { code: 287, name: "Wheaten Terrier" },
    { code: 288, name: "Whippet" },
    { code: 289, name: "Wolfhound Irlandés" },
    { code: 290, name: "Yorkshire Terrier" },
    { code: 291, name: "Zepter" },
    { code: 292, name: "Zugalo" },
    { code: 293, name: "Zusky" },
  ],
  HOLIDAYS: [
    {
      date: "2023-01-01 00:00:00",
      start: "2023-01-01T00:00:00.000Z",
      end: "2023-01-02T00:00:00.000Z",
      name: "Año Nuevo",
      type: "public",
      rule: "01-01",
    },
    {
      date: "2023-01-02 00:00:00",
      start: "2023-01-02T00:00:00.000Z",
      end: "2023-01-03T00:00:00.000Z",
      name: "Año Nuevo (día sustituto)",
      type: "observance",
      substitute: true,
      rule: "substitutes 01-01 if sunday then next monday",
    },
    {
      date: "2023-01-06 00:00:00",
      start: "2023-01-06T00:00:00.000Z",
      end: "2023-01-07T00:00:00.000Z",
      name: "Día de los Reyes Magos",
      type: "public",
      rule: "01-06",
    },
    {
      date: "2023-03-19 00:00:00",
      start: "2023-03-19T00:00:00.000Z",
      end: "2023-03-20T00:00:00.000Z",
      name: "San José",
      type: "observance",
      rule: "03-19",
    },
    {
      date: "2023-04-06 00:00:00",
      start: "2023-04-05T23:00:00.000Z",
      end: "2023-04-06T23:00:00.000Z",
      name: "Jueves Santo",
      type: "public",
      rule: "easter -3",
    },
    {
      date: "2023-04-07 00:00:00",
      start: "2023-04-06T23:00:00.000Z",
      end: "2023-04-07T23:00:00.000Z",
      name: "Viernes Santo",
      type: "public",
      rule: "easter -2",
    },
    {
      date: "2023-04-09 00:00:00",
      start: "2023-04-08T23:00:00.000Z",
      end: "2023-04-09T23:00:00.000Z",
      name: "Pascua",
      type: "observance",
      rule: "easter",
    },
    {
      date: "2023-05-01 00:00:00",
      start: "2023-04-30T23:00:00.000Z",
      end: "2023-05-01T23:00:00.000Z",
      name: "Día del trabajador",
      type: "public",
      rule: "05-01",
    },
    {
      date: "2023-05-07 00:00:00",
      start: "2023-05-06T23:00:00.000Z",
      end: "2023-05-07T23:00:00.000Z",
      name: "Día de la Madre",
      type: "observance",
      rule: "1st sunday in May",
    },
    {
      date: "2023-05-28 00:00:00",
      start: "2023-05-27T23:00:00.000Z",
      end: "2023-05-28T23:00:00.000Z",
      name: "Pentecostés",
      type: "observance",
      rule: "easter 49",
    },
    {
      date: "2023-05-30 00:00:00",
      start: "2023-05-29T23:00:00.000Z",
      end: "2023-05-30T23:00:00.000Z",
      name: "Día de Canarias",
      type: "public",
      rule: "05-30",
    },
    {
      date: "2023-07-25 00:00:00",
      start: "2023-07-24T23:00:00.000Z",
      end: "2023-07-25T23:00:00.000Z",
      name: "Santiago Apostol",
      type: "observance",
      note: "regional",
      rule: "07-25",
    },
    {
      date: "2023-08-05 00:00:00",
      start: "2023-08-04T23:00:00.000Z",
      end: "2023-08-05T23:00:00.000Z",
      name: "Nuestra Señora de las Nieves",
      type: "public",
      rule: "08-05",
    },
    {
      date: "2023-08-15 00:00:00",
      start: "2023-08-14T23:00:00.000Z",
      end: "2023-08-15T23:00:00.000Z",
      name: "Asunción",
      type: "public",
      rule: "08-15",
    },
    {
      date: "2023-10-12 00:00:00",
      start: "2023-10-11T23:00:00.000Z",
      end: "2023-10-12T23:00:00.000Z",
      name: "Fiesta Nacional de España",
      type: "public",
      rule: "10-12",
    },
    {
      date: "2023-11-01 00:00:00",
      start: "2023-11-01T00:00:00.000Z",
      end: "2023-11-02T00:00:00.000Z",
      name: "Todos los Santos",
      type: "public",
      rule: "11-01",
    },
    {
      date: "2023-12-06 00:00:00",
      start: "2023-12-06T00:00:00.000Z",
      end: "2023-12-07T00:00:00.000Z",
      name: "Día de la Constitución Española",
      type: "public",
      rule: "12-06",
    },
    {
      date: "2023-12-08 00:00:00",
      start: "2023-12-08T00:00:00.000Z",
      end: "2023-12-09T00:00:00.000Z",
      name: "La inmaculada concepción",
      type: "public",
      rule: "12-08",
    },
    {
      date: "2023-12-25 00:00:00",
      start: "2023-12-25T00:00:00.000Z",
      end: "2023-12-26T00:00:00.000Z",
      name: "Navidad",
      type: "public",
      rule: "12-25",
    },
    {
      date: "2024-01-01 00:00:00",
      start: "2024-01-01T00:00:00.000Z",
      end: "2024-01-02T00:00:00.000Z",
      name: "Año Nuevo",
      type: "public",
      rule: "01-01",
    },
    {
      date: "2024-01-06 00:00:00",
      start: "2024-01-06T00:00:00.000Z",
      end: "2024-01-07T00:00:00.000Z",
      name: "Día de los Reyes Magos",
      type: "public",
      rule: "01-06",
    },
    {
      date: "2024-03-19 00:00:00",
      start: "2024-03-19T00:00:00.000Z",
      end: "2024-03-20T00:00:00.000Z",
      name: "San José",
      type: "observance",
      rule: "03-19",
    },
    {
      date: "2024-03-28 00:00:00",
      start: "2024-03-28T00:00:00.000Z",
      end: "2024-03-29T00:00:00.000Z",
      name: "Jueves Santo",
      type: "public",
      rule: "easter -3",
    },
    {
      date: "2024-03-29 00:00:00",
      start: "2024-03-29T00:00:00.000Z",
      end: "2024-03-30T00:00:00.000Z",
      name: "Viernes Santo",
      type: "public",
      rule: "easter -2",
    },
    {
      date: "2024-03-31 00:00:00",
      start: "2024-03-31T00:00:00.000Z",
      end: "2024-03-31T23:00:00.000Z",
      name: "Pascua",
      type: "observance",
      rule: "easter",
    },
    {
      date: "2024-05-01 00:00:00",
      start: "2024-04-30T23:00:00.000Z",
      end: "2024-05-01T23:00:00.000Z",
      name: "Día del trabajador",
      type: "public",
      rule: "05-01",
    },
    {
      date: "2024-05-05 00:00:00",
      start: "2024-05-04T23:00:00.000Z",
      end: "2024-05-05T23:00:00.000Z",
      name: "Día de la Madre",
      type: "observance",
      rule: "1st sunday in May",
    },
    {
      date: "2024-05-19 00:00:00",
      start: "2024-05-18T23:00:00.000Z",
      end: "2024-05-19T23:00:00.000Z",
      name: "Pentecostés",
      type: "observance",
      rule: "easter 49",
    },
    {
      date: "2024-05-30 00:00:00",
      start: "2024-05-29T23:00:00.000Z",
      end: "2024-05-30T23:00:00.000Z",
      name: "Día de Canarias",
      type: "public",
      rule: "05-30",
    },
    {
      date: "2024-07-25 00:00:00",
      start: "2024-07-24T23:00:00.000Z",
      end: "2024-07-25T23:00:00.000Z",
      name: "Santiago Apostol",
      type: "observance",
      note: "regional",
      rule: "07-25",
    },
    {
      date: "2024-08-05 00:00:00",
      start: "2024-08-04T23:00:00.000Z",
      end: "2024-08-05T23:00:00.000Z",
      name: "Nuestra Señora de las Nieves",
      type: "public",
      rule: "08-05",
    },
    {
      date: "2024-08-15 00:00:00",
      start: "2024-08-14T23:00:00.000Z",
      end: "2024-08-15T23:00:00.000Z",
      name: "Asunción",
      type: "public",
      rule: "08-15",
    },
    {
      date: "2024-10-12 00:00:00",
      start: "2024-10-11T23:00:00.000Z",
      end: "2024-10-12T23:00:00.000Z",
      name: "Fiesta Nacional de España",
      type: "public",
      rule: "10-12",
    },
    {
      date: "2024-11-01 00:00:00",
      start: "2024-11-01T00:00:00.000Z",
      end: "2024-11-02T00:00:00.000Z",
      name: "Todos los Santos",
      type: "public",
      rule: "11-01",
    },
    {
      date: "2024-12-06 00:00:00",
      start: "2024-12-06T00:00:00.000Z",
      end: "2024-12-07T00:00:00.000Z",
      name: "Día de la Constitución Española",
      type: "public",
      rule: "12-06",
    },
    {
      date: "2024-12-08 00:00:00",
      start: "2024-12-08T00:00:00.000Z",
      end: "2024-12-09T00:00:00.000Z",
      name: "La inmaculada concepción",
      type: "public",
      rule: "12-08",
    },
    {
      date: "2024-12-09 00:00:00",
      start: "2024-12-09T00:00:00.000Z",
      end: "2024-12-10T00:00:00.000Z",
      name: "La inmaculada concepción (día sustituto)",
      type: "observance",
      substitute: true,
      rule: "substitutes 12-08 if sunday then next monday",
    },
    {
      date: "2024-12-25 00:00:00",
      start: "2024-12-25T00:00:00.000Z",
      end: "2024-12-26T00:00:00.000Z",
      name: "Navidad",
      type: "public",
      rule: "12-25",
    },
  ],
  CANCEL_REASONS: [
    "Necesita un alimento especial recomendado por su veterinario",
    "Me falta espacio en el congelador",
    "Inconvenientes con la entrega del servicio",
    "No le gusta el sabor de la comida",
    "Experiencia negativa con el servicio proporcionado",
    "Precio demasiado alto",
    "Le ha sentado mal a mi perro (malestar estomacal, problemas digestivos, etc.)",
    "Deseo combinar Candi con otro tipo de alimento",
    "No consume toda la ración y tengo aún comida restante",
    "Lamentablemente, mi perro ha fallecido",
    "Prefiero gestionar yo misma/o la alimentación de mi perro",
    "Planeo irme de vacaciones",
    "Otro motivo",
  ],
  TWO_SHIPPINGS_THRESHOLD: +(process.env.TWO_SHIPPINGS_THRESHOLD || "500"),
};
