import { useEffect, useState } from "react";
import { IPetOrder, IPetOrderMenu } from "../types/types";

import { useSteps } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import OnboardingStep1 from "../components/OnboardingStep1";
import OnboardingStep2 from "../components/OnboardingStep2";
import OnboardingStep3 from "../components/OnboardingStep3";
import OnboardingStep4 from "../components/OnboardingStep4";
import OnboardingStep5 from "../components/OnboardingStep5";
import { ERROR_MESSAGES } from "../constants/error_messages";
import { OnboardingService } from "../services/onboarding.service";

export interface IOnboardingContext {
  goNextStep: () => void;
  data: IOnboardingData;
  setData: React.Dispatch<React.SetStateAction<IOnboardingData>>;
  menusChosen: {
    [po_id: string]: string[];
  };
  setMenusChosen: React.Dispatch<
    React.SetStateAction<{
      [po_id: string]: string[];
    }>
  >;
  errorMsgs: string[];
  setStepSummary: React.Dispatch<React.SetStateAction<boolean>>;
  handleOnboardingDogDataChange: ({
    index,
    key,
    value,
  }: {
    index: number;
    key: string;
    value: any;
  }) => void;
  onboardingSubmitLoading: boolean;
  handleOnboardingStepsSubmit: () => void;
  planPriceSubtotal: number | undefined;
  planPriceTotal: number | undefined;
}

export interface IOnboardingData {
  n_of_pets?: number;
  pets: IOnboardingDataPet[];
  pet_orders: IPetOrder[];
  user: {
    auth0?: string;
    stripe?: string;
    user_email?: string;
    user_phone_number?: string;
    first_name?: string;
    last_name?: string;
  };
}

export interface IOnboardingDataPet {
  _id?: string;
  user?: string;
  name?: string;
  gender?: "male" | "female";
  sterilized?: boolean;
  age_group?: "young" | "adult" | "senior";
  activity_level?: "very-low" | "low" | "high" | "very-high";
  body_condition?: "very-thin" | "thin" | "ideal" | "overweight" | "obese";
  weight?: string;
  breed_code?: number;
  illness?: string | null;
  daily_grams: number;
}

export interface IMenusChosen {
  [po_id: string]: string[];
}

const onboardingService = new OnboardingService();

export const useOnboarding = () => {
  const navigate = useNavigate();

  const stepComponents = [
    <OnboardingStep1 key={"1"} />,
    <OnboardingStep2 key={"2"} />,
    <OnboardingStep3 key={"3"} />,
    <OnboardingStep4 key={"4"} />,
    <OnboardingStep5 key={"5"} />,
  ];

  const { activeStep, goToNext, goToPrevious, setActiveStep } = useSteps({
    index: 1,
    count: stepComponents.length,
  });

  const [direction, setDirection] = useState<{
    prevStep: Number;
    direction: "back" | "forward";
  }>();

  const [data, setData] = useState<IOnboardingData>({
    n_of_pets: undefined,
    pets: [],
    pet_orders: [],
    user: {
      user_email: undefined,
      user_phone_number: undefined,
      auth0: undefined,
      stripe: undefined,
    },
  });
  const [menusChosen, setMenusChosen] = useState<IMenusChosen>({});

  const [planPriceSubtotal, setPlanPriceSubtotal] = useState<number>();
  const [planPriceTotal, setPlanPrice] = useState<number>();

  const [stepSummary, setStepSummary] = useState<boolean>(false);
  const [stripePaymentIntentClientSecret, setStripePaymentIntentClientSecret] =
    useState<string | undefined>();

  const [onboardingSubmitLoading, setOnboardingSubmitLoading] =
    useState<boolean>(false);

  const [errorMsgs, setErrorMsgs] = useState<{
    "1": string[];
    "2": string[];
    "3": string[];
    "4": string[];
    "5": string[];
    [step: string]: string[];
  }>({
    "1": [],
    "2": [],
    "3": [],
    "4": [],
    "5": [],
  });

  const [localStorageLoaded, setLocalStorageLoaded] = useState<boolean>(false);

  const goPrevStep = () => {
    setDirection({ prevStep: activeStep, direction: "back" });
  };
  const goNextStep = () => {
    setDirection({ prevStep: activeStep, direction: "forward" });
  };
  useEffect(
    () => {
      if (direction?.direction === "back") {
        goToPrevious();
      } else if (direction?.direction === "forward") {
        goToNext();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [direction]
  );

  useEffect(() => {
    if (!data || !data.pet_orders) return;

    let newMenusChosen: IMenusChosen = {};
    data.pet_orders.forEach((po) => {
      Object.keys(po.menu).forEach((m) => {
        if (po.menu[m] > 0) {
          newMenusChosen[po._id] = [
            ...(newMenusChosen[po._id] || []),
            m as string,
          ];
        }
      });
    });

    setMenusChosen(newMenusChosen);
  }, [data]);

  useEffect(() => {
    const activeStep = localStorage.getItem("onboarding__activeStep");
    const onboarding_data = localStorage.getItem("onboarding__data");
    const menus_chosen = localStorage.getItem("onboarding__menus_chosen");
    const stripe_payment_intent_client_secret = localStorage.getItem(
      "onboarding__stripe_pi_secret"
    );
    const plan_price_subtotal = localStorage.getItem(
      "onboarding__plan_price_subtotal"
    );
    const plan_price_total = localStorage.getItem(
      "onboarding__plan_price_total"
    );

    if (activeStep) {
      setActiveStep(parseInt(activeStep));
    }

    if (onboarding_data) {
      setData(JSON.parse(onboarding_data));
    }

    if (menus_chosen) {
      setMenusChosen(JSON.parse(menus_chosen));
    }

    if (
      stripe_payment_intent_client_secret &&
      stripe_payment_intent_client_secret !== ""
    ) {
      setStripePaymentIntentClientSecret(stripe_payment_intent_client_secret);
      setStepSummary(true);
      navigate("/onboarding/payment");
    }

    if (plan_price_subtotal && plan_price_subtotal !== "") {
      setPlanPriceSubtotal(parseFloat(plan_price_subtotal));
    }

    if (plan_price_total && plan_price_total !== "") {
      setPlanPrice(parseFloat(plan_price_total));
    }

    setLocalStorageLoaded(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!localStorageLoaded) return;

    localStorage.setItem(
      "onboarding__activeStep",
      stepSummary === true ? "summary" : activeStep.toString()
    );
    localStorage.setItem("onboarding__data", JSON.stringify(data));
    localStorage.setItem(
      "onboarding__menus_chosen",
      JSON.stringify(menusChosen)
    );

    if (stripePaymentIntentClientSecret) {
      localStorage.setItem(
        "onboarding__stripe_pi_secret",
        stripePaymentIntentClientSecret
      );
    }

    if (planPriceSubtotal) {
      localStorage.setItem(
        "onboarding__plan_price_subtotal",
        planPriceSubtotal.toString()
      );
    }

    if (planPriceTotal) {
      localStorage.setItem(
        "onboarding__plan_price_total",
        planPriceTotal.toString()
      );
    }
  }, [
    localStorageLoaded,
    activeStep,
    stepSummary,
    data,
    menusChosen,
    stripePaymentIntentClientSecret,
    planPriceSubtotal,
    planPriceTotal,
  ]);

  const handleOnboardingPetDataChange = ({
    index,
    key,
    value,
  }: {
    index: number;
    key: string;
    value: any;
  }) => {
    setData((prevData) => ({
      ...prevData,
      ...data,
      pets: data.pets.map((pet, petIndex) => {
        return petIndex === index ? { ...pet, [key]: value } : pet;
      }),
    }));
  };

  const handleOnboardingStepsSubmit = () => {
    setErrorMsgs({ ...errorMsgs, "5": [] });
    setOnboardingSubmitLoading(true);

    onboardingService
      .onboarding_initial(
        data.user.user_email!,
        data.user.user_phone_number!,
        data.pets
      )
      .then(({ data: response_data }) => {
        console.log("response_data", response_data);

        const plan_price = response_data.plan_total_amount;
        setPlanPriceSubtotal(plan_price);
        setPlanPrice(plan_price);

        setStripePaymentIntentClientSecret(
          response_data.stripe_payment_intent_client_secret
        );

        setData((prevData) => ({
          ...prevData,
          pets: data.pets.map((p) => {
            return {
              ...p,
              _id: response_data.pets.find((pet) => pet.name === p.name)?._id,
              daily_grams: response_data.pets.find((pet) => pet.name === p.name)
                ?.daily_grams as number,
            };
          }),
          pet_orders: response_data.pet_orders.map((po) => {
            return {
              _id: po._id,
              pet: po.pet!,
              total_daily_grams: po.total_daily_grams,
              bag_count: po.bag_count,
              deliveries: po.deliveries,
              daily_bags: po.daily_bags as { [grams: string]: number },
              menu: po.menu as IPetOrderMenu,
            };
          }),
          user: {
            ...data.user,
            // auth0: response_data.user.auth0,
            stripe: response_data.user.stripe,
          },
        }));

        setStepSummary(true);
        navigate("/onboarding/payment");
      })
      .catch((err) => {
        setErrorMsgs((prevErrorMsgs) => ({
          ...prevErrorMsgs,
          "5": [
            ...errorMsgs["5"],
            ERROR_MESSAGES[err.response.data.code] || err.response.data.code,
          ],
        }));

        console.error(err);
        console.log(err.response);
      })
      .finally(() => setOnboardingSubmitLoading(false));
  };

  return {
    stepComponents,
    activeStep,
    direction,
    goNextStep,
    goPrevStep,
    data,
    setData,
    menusChosen,
    setMenusChosen,
    planPriceSubtotal,
    planPriceTotal,
    errorMsgs,
    stepSummary,
    setStepSummary,
    stripePaymentIntentClientSecret,
    onboardingSubmitLoading,
    handleOnboardingPetDataChange,
    handleOnboardingStepsSubmit,
  };
};
