import {
  Button,
  Image,
  ResponsiveValue,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

import { HTMLChakraProps } from "@chakra-ui/system";
import React from "react";

interface IOnboardingRadioButtonGroup {
  name: string;
  defaultValue?: string;
  value: string;
  columns: ResponsiveValue<number>;
  onChange: (value: string) => void;
  options: { value: string; caption: string; icon?: string }[];
  groupStyle?: HTMLChakraProps<"div">;
  childrenStyle?: HTMLChakraProps<"div">;
}

const OnboardingRadioButtonGroup: React.FC<IOnboardingRadioButtonGroup> = ({
  name,
  defaultValue,
  value,
  columns,
  onChange,
  options,
  groupStyle,
  childrenStyle,
}) => {
  return (
    <SimpleGrid spacing={{ base: 4, lg: 8 }} columns={columns}>
      {options.map((option) => (
        <Button
          key={option.caption}
          h={"fit-content"}
          aspectRatio={"1 / 1"}
          variant={"outline"}
          p={6}
          borderWidth={"3px"}
          colorScheme="secondary"
          borderRadius={"xl"}
          borderColor="secondary.500"
          color="secondary.500"
          _active={{ bgColor: "secondary.100", color: "white" }}
          isActive={value === option.value}
          onClick={() => {
            onChange(option.value);
          }}
        >
          <VStack justifyContent={"center"} alignItems={"center"} spacing={4}>
            {option.icon && (
              <Image src={option.icon} h={"100px"} aspectRatio={"1 / 1"} />
            )}
            <Text fontWeight={"semibold"} fontSize={18}>
              {option.caption}
            </Text>
          </VStack>
        </Button>
      ))}
    </SimpleGrid>
  );
};

export default OnboardingRadioButtonGroup;
