import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Icon,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { AddressElement, Elements } from "@stripe/react-stripe-js";
import { useContext, useEffect, useState } from "react";

import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { HiChevronRight } from "react-icons/hi2";
import Stripe from "stripe";
import { stripePromise } from "..";
import { UserContext } from "../App";
import { UserService } from "../services/user.service";

const userService = new UserService();

const EditAddress: React.FC<{
  currentAddress?: {
    firstName?: string;
    lastName?: string;
    address?: Stripe.Address;
  };
  onAddressChange?: () => void;
}> = ({ currentAddress, onAddressChange }) => {
  const toast = useToast();

  const { getUser } = useContext(UserContext);

  const [stripeAddressElement, setStripeAddressElement] = useState<
    StripeAddressElementChangeEvent | undefined
  >();
  const [validAddress, setValidAddress] = useState<boolean>(false);
  const [showLocationWarning, setShowLocationWarning] =
    useState<boolean>(false);
  const [addressChangeLoading, setAddressChangeLoading] =
    useState<boolean>(false);

  useEffect(() => {
    validateAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripeAddressElement]);

  const validateAddress = () => {
    const validLocation =
      stripeAddressElement?.value.address.postal_code.startsWith("35") &&
      stripeAddressElement?.value.address.state === "GC";

    const valid = stripeAddressElement?.complete && validLocation;

    setShowLocationWarning(
      stripeAddressElement?.complete !== true ? false : !validLocation
    );
    setValidAddress(Boolean(valid));
  };

  const handleAddressChange = async (address: Stripe.Address) => {
    setAddressChangeLoading(true);

    userService
      .update_address({ address })
      .then(({ data }) => {
        getUser();

        toast({
          title: "Se ha modificado la dirección",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        if (onAddressChange) {
          onAddressChange();
        }
      })
      .catch((err) => {
        console.error(err);

        toast({
          title: "Ha ocurrido un error. Por favor inténtalo de nuevo.",
          description: `${err}`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      })
      .finally(() => setAddressChangeLoading(false));
  };

  return (
    <VStack w={"full"} spacing={8}>
      <Elements
        stripe={stripePromise}
        options={{
          loader: "always",
          locale: "es",
          appearance: {
            variables: {
              colorPrimary: "#FED144",
              borderRadius: "16px",
            },
          },
        }}
      >
        <Box w={"full"}>
          <Alert status="info" borderRadius={"2xl"} mb={4}>
            <AlertIcon alignSelf={"flex-start"} />
            Al realizar esta acción, se actualizará tu dirección de entrega por
            defecto para todos tus pedidos programados y futuros. Los pedidos
            que ya estén en curso conservarán la dirección actual asociada.
          </Alert>

          <AddressElement
            options={{
              mode: "shipping",
              allowedCountries: ["ES"],
              autocomplete: {
                mode: "disabled",
              },
              defaultValues: {
                firstName: currentAddress?.firstName || "",
                lastName: currentAddress?.lastName || "",
                address: {
                  line1: currentAddress?.address?.line1 || "",
                  line2: currentAddress?.address?.line2 || "",
                  city: currentAddress?.address?.city || "",
                  state: currentAddress?.address?.state || "",
                  postal_code: currentAddress?.address?.postal_code || "",
                  country: "ES",
                },
              },
              display: { name: "split" },
            }}
            onChange={(event) => {
              console.log("event", event);
              setStripeAddressElement(event);
            }}
          />
          {showLocationWarning && (
            <Alert status="error" fontSize={"sm"} borderRadius={"2xl"} mt={8}>
              <AlertIcon alignSelf={"flex-start"} />
              Por el momento, solo estamos activos en Las Palmas, ¡te
              informaremos cuando estemos disponibles en tu zona!
            </Alert>
          )}
        </Box>
      </Elements>
      <VStack w={"full"} alignItems={"flex-end"}>
        <Button
          size={"md"}
          borderRadius={"full"}
          color={"white"}
          colorScheme={"secondary"}
          rightIcon={<Icon as={HiChevronRight} ml={10} />}
          justifyContent={"space-between"}
          isDisabled={!validAddress}
          isLoading={addressChangeLoading}
          onClick={() =>
            handleAddressChange(stripeAddressElement?.value.address!)
          }
        >
          Guardar
        </Button>
      </VStack>
    </VStack>
  );
};

export default EditAddress;
