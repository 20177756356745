import {
  Box,
  Button,
  Heading,
  Icon,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import { Select as ChakraSelect } from "chakra-react-select";
import { HiChevronRight } from "react-icons/hi2";
import { ENV } from "../constants/environment.base";
import { getBreedName } from "../constants/utils";
import Section from "../containers/Section";
import { OnboardingContext } from "../pages/Onboarding";

const HEADING = {
  singular: "Con esto, ¡terminamos!",
  plural: "Con esto, ¡terminamos!",
};

const OnboardingStep4 = () => {
  const { goNextStep, data, handleOnboardingDogDataChange } =
    useContext(OnboardingContext);

  const [validStep, setValidStep] = useState(false);

  useEffect(() => {
    validateStep();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const validateStep = () => {
    const valid_pets = data.pets.every(
      (dog) =>
        dog.hasOwnProperty("breed_code") &&
        dog.hasOwnProperty("illness") &&
        dog.illness !== "other"
    );

    setValidStep(valid_pets);
  };

  return (
    <VStack alignItems={"center"} spacing={{ base: 6, md: 12 }} w={"full"}>
      <Heading as={"h1"} fontWeight="bold" fontSize={{ base: 25, md: 40 }}>
        {data.n_of_pets! > 1 ? HEADING.plural : HEADING.singular}
      </Heading>
      {/* <HStack
        divider={<StackDivider color={"gray.200"} />}
        w={"full"}
        spacing={8}
      > */}
      <SimpleGrid
        spacing={{ base: 4, md: 6, lg: 8 }}
        columns={{ base: 1, lg: data.n_of_pets === 1 ? 1 : 2 }}
        w={"full"}
      >
        {data.pets.map((pet, petIndex) => (
          <Box key={pet.name}>
            <Section title={pet.name} chakraStyles={{ bgColor: "white" }}>
              <VStack alignItems={"start"} spacing={4}>
                <Box w={"full"}>
                  <ChakraSelect
                    variant="filled"
                    colorScheme="brand"
                    placeholder={`Raza de ${pet.name}`}
                    isSearchable
                    defaultInputValue={getBreedName(
                      data.pets[petIndex]!.breed_code!
                    )}
                    noOptionsMessage={() => "No se ha encontrado la raza"}
                    options={ENV.DOG_BREEDS.map((breed) => {
                      return {
                        label: breed.name,
                        value: breed.code,
                      };
                    })}
                    onChange={(e) =>
                      e &&
                      handleOnboardingDogDataChange({
                        index: petIndex,
                        key: "breed_code",
                        value: e.value,
                      })
                    }
                  />
                </Box>
                <VStack alignItems={"start"} spacing={2} w={"full"}>
                  <Text fontSize={16} fontWeight={"medium"}>
                    ¿Alguna enfermedad?
                  </Text>
                  <Box w={"full"}>
                    <ChakraSelect
                      variant="filled"
                      colorScheme="brand"
                      isSearchable
                      defaultInputValue={data.pets[petIndex]!.illness!}
                      placeholder="Selecciona una opción"
                      options={[
                        { label: "Ninguna", value: "none" },
                        {
                          label: "Digestiones sensibles",
                          value: "digestiones-sensibles",
                        },
                        {
                          label: "Problemas de piel",
                          value: "problemas-piel",
                        },
                        {
                          label: "Problemas articulares",
                          value: "problemas-articulares",
                        },
                        {
                          label: "Problemas dentales",
                          value: "problemas-dentales",
                        },
                        { label: "Diabetes", value: "diabetes" },
                        { label: "Epilepsia", value: "epilepsia" },
                        { label: "Cáncer", value: "cancer" },
                        { label: "Otitis", value: "otitis" },
                        {
                          label: "Síndrome de Cushing",
                          value: "sindrome-cushing",
                        },
                        { label: "Hipotiroidismo", value: "hipotiroidismo" },
                        { label: "Otra", value: "other" },
                      ]}
                      onChange={(e) =>
                        e &&
                        handleOnboardingDogDataChange({
                          index: petIndex,
                          key: "illness",
                          value: e.value === "none" ? null : e.value,
                        })
                      }
                    />
                  </Box>
                </VStack>
              </VStack>
            </Section>
          </Box>
        ))}
      </SimpleGrid>
      {/* </HStack> */}

      {data.pets.find((d) => d.illness === "other") && (
        <Text
          fontSize={14}
          textAlign={"center"}
          color={"gray.500"}
          mt={4}
          maxW={"container.sm"}
        >
          Contacta con nosotros en{" "}
          <a
            href={`mailto:consulta@candidogmenu.com?subject=Mi peludo necesita un menú personalizado!&body=¡Hola!\n Mi peludo sufre de _______________ y necesita un menú más personalizado. \n¡Saludos!`}
            style={{ textDecoration: "underline" }}
          >
            consulta@candidogmenu.com
          </a>{" "}
          para asegurarnos de hacer un menú personalizado para tu peludo
        </Text>
      )}
      <Button
        size={"lg"}
        borderRadius={"full"}
        color={"white"}
        colorScheme="brand"
        type="submit"
        rightIcon={<Icon as={HiChevronRight} ml={10} />}
        justifyContent={"space-between"}
        isDisabled={!validStep}
        onClick={() => goNextStep()}
      >
        Continuar
      </Button>
    </VStack>
  );
};

export default OnboardingStep4;
