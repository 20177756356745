import {
  Box,
  Container,
  Image,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import React from "react";
import { Link } from "react-router-dom";
import { images } from "../assets/images/images";

const Footer: React.FC = () => {
  return (
    <Box
      bg={useColorModeValue("gray.50", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
      mt={"auto"}
      borderTopLeftRadius={"xl"}
      borderTopRightRadius={"xl"}
    >
      <Container as={Stack} px={6} py={10} maxW={"full"}>
        <SimpleGrid
          templateColumns={{ sm: "1fr 1fr", md: "2fr 4fr" }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Image src={images.logo_candi_yellow} w="150px" />
            </Box>
            <Text fontSize={"sm"}>© {new Date().getFullYear()} Candi</Text>
          </Stack>
          <Stack align={"flex-start"}>
            <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
              Información
            </Text>
            <Link to={"#"}>Política de privacidad</Link>
            <Link to={"#"}>Aviso legal</Link>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

export default Footer;
