import { useContext, useEffect, useState } from "react";

import Auth0 from "auth0";
import Stripe from "stripe";
import { UserContext } from "../App";
import { StripeService } from "../services/stripe.service";
import { IUserExpanded } from "../types/types";

const stripeService = new StripeService();

export const useProfileAddressData = () => {
  const { user } = useContext(UserContext);

  const [stripeCustomerAddress, setStripeCustomerAddress] = useState<
    Stripe.Address | undefined
  >();
  const [stripeCustomerAddressLoading, setStripeCustomerAddressLoading] =
    useState<boolean>(false);

  useEffect(() => {
    if (user) {
      getStripeCustomer(user.stripe.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getStripeCustomer = async (customer: string) => {
    setStripeCustomerAddressLoading(true);

    stripeService
      .customer(customer)
      .then(({ data }) => setStripeCustomerAddress(data.address!))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setStripeCustomerAddressLoading(false);
      });
  };

  return {
    firstName: ((user as IUserExpanded).auth0 as Auth0.User).given_name,
    lastName: ((user as IUserExpanded).auth0 as Auth0.User).family_name,
    stripeCustomerAddress,
    stripeCustomerAddressLoading,
  };
};
