import {
  Alert,
  AlertIcon,
  Badge,
  Box,
  Button,
  Collapse,
  HStack,
  Icon,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  HiChevronRight,
  HiOutlineArrowRight,
  HiOutlineCreditCard,
  HiXMark,
} from "react-icons/hi2";

import { PaymentElement } from "@stripe/react-stripe-js";
import React from "react";
import Section from "../containers/Section";
import { useProfilePaymentData } from "../hooks/useProfilePaymentData";

const ProfilePaymentData: React.FC = () => {
  const {
    userPaymentMethods,
    userPaymentMethodsLoading,
    onToggle,
    isOpen,
    handleStripePaymentMethodSubmit,
    stripePaymentMethodSubmitLoading,
    stripeErrorMessage,
  } = useProfilePaymentData();

  return (
    <Section title="Método de pago">
      <VStack align={"flex-start"} w={"full"} spacing={6}>
        <Skeleton
          isLoaded={!userPaymentMethodsLoading}
          as={VStack}
          spacing={6}
          align={"flex-start"}
          w={"full"}
        >
          {userPaymentMethods && userPaymentMethods.length > 0 ? (
            <>
              <HStack spacing={4}>
                <Icon as={HiOutlineCreditCard} h={8} w={10} strokeWidth={1} />
                <VStack alignItems={"flex-start"} spacing={1}>
                  <HStack>
                    <Text lineHeight={1}>
                      <b>···· ···· ···· {userPaymentMethods[0].card?.last4}</b>
                    </Text>
                    <Badge colorScheme="green" mb={2}>
                      Válida
                    </Badge>
                  </HStack>
                  <Text lineHeight={1} fontSize={"sm"} color={"gray.500"}>
                    Expira {userPaymentMethods[0].card!.exp_month}/
                    {userPaymentMethods[0].card!.exp_year}
                  </Text>
                </VStack>
              </HStack>
            </>
          ) : null}

          <Button
            rightIcon={isOpen ? <HiXMark /> : <HiOutlineArrowRight />}
            colorScheme="gray"
            variant="link"
            onClick={() => onToggle()}
          >
            {userPaymentMethods && userPaymentMethods.length > 0 ? (
              <>Modificar método de pago</>
            ) : (
              <>Añadir un método de pago</>
            )}
          </Button>
        </Skeleton>

        <Collapse in={isOpen} style={{ width: "100%", overflow: "visible" }}>
          <VStack
            as={"form"}
            w={"full"}
            onSubmit={handleStripePaymentMethodSubmit}
          >
            <Box w={"full"}>
              <PaymentElement
                options={{
                  layout: "auto",
                  fields: { billingDetails: "never" },
                }}
              />
            </Box>
            <Button
              size={"md"}
              mt={4}
              borderRadius={"full"}
              color={"white"}
              colorScheme={"secondary"}
              rightIcon={<Icon as={HiChevronRight} ml={10} />}
              justifyContent={"space-between"}
              alignSelf={"flex-end"}
              // isDisabled={!validAddress}
              isLoading={stripePaymentMethodSubmitLoading}
              type="submit"
            >
              Guardar
            </Button>
          </VStack>
        </Collapse>

        {stripeErrorMessage && (
          <Alert status="error">
            <AlertIcon />
            {stripeErrorMessage}
          </Alert>
        )}
      </VStack>
    </Section>
  );
};

export default ProfilePaymentData;
