import { Box, Flex, Heading, Text } from "@chakra-ui/react";

import { CloseIcon } from "@chakra-ui/icons";
import React from "react";
import { Link } from "react-router-dom";

const NoMatch: React.FC = () => {
  return (
    <>
      <Box display="inline-block">
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          bg={"red.500"}
          rounded={"0"}
          w={"155px"}
          h={"55px"}
          textAlign="center"
        >
          <CloseIcon boxSize={"20px"} color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        No se ha encontrado lo que buscabas
      </Heading>
      <Text color={"gray.500"}>
        El recurso que buscabas no se ha encontrado.
      </Text>
      <Link to="/">Volver a la página principal</Link>
    </>
  );
};

export default NoMatch;
