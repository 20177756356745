import { useContext, useEffect, useState } from "react";
import {
  HiOutlineCalendar,
  HiOutlineHome,
  HiOutlinePauseCircle,
  HiOutlineTruck,
} from "react-icons/hi2";
import { JwtContext, UserContext } from "../App";

import { useDisclosure } from "@chakra-ui/react";
import { ENV } from "../constants/environment.base";
import { DeliveryService } from "../services/delivery.service";
import { IDelivery } from "../types/types";

const deliveryService = new DeliveryService();

export const useDeliveries = () => {
  const { jwt } = useContext(JwtContext);
  const { user, validSubscription, petOrders } = useContext(UserContext);

  const [deliveriesPending, setDeliveriesPending] = useState<
    IDelivery[] | undefined
  >();
  const [deliveriesPendingLoading, setDeliveriesPendingLoading] =
    useState<boolean>(false);

  const [deliveriesHistory, setDeliveriesHistory] = useState<
    IDelivery[] | undefined
  >();
  const [deliveriesHistoryLoading, setDeliveriesHistoryLoading] =
    useState<boolean>(false);

  const [deliveriesActions] = useState<
    {
      id: string;
      caption: string;
      description: string;
      icon: React.FC;
      trigger: () => void;
      isDisabled: boolean;
    }[]
  >([
    {
      id: "postpone",
      caption: "Aplazar las entregas",
      description: "¿Todavía tienes comida? ¡Aplaza las entregas!",
      icon: HiOutlineCalendar,
      trigger: () => onDeliveriesPostponeModalOpen(),
      isDisabled: validSubscription === false,
    },
    {
      id: "address_change",
      caption: "Cambio de dirección",
      description: "¿Vacaciones? ¿Cambio de dirección? ¡No hay problema!",
      icon: HiOutlineHome,
      trigger: () => onDeliveriesAddressModalOpen(),
      isDisabled: validSubscription === false,
    },
    {
      id: "pause",
      caption: "Pausar las entregas",
      description: "Puedes pausar las entregas sin compromiso",
      icon: HiOutlinePauseCircle,
      trigger: () => onDeliveriesPauseOpen(),
      isDisabled: validSubscription === false,
    },
    {
      id: "frequency_change",
      caption: "Frecuencia de entrega",
      description: "Modifica la frecuencia de entrega de tu plan",
      icon: HiOutlineTruck,
      trigger: () => onDeliveriesFrequencyOpen(),
      isDisabled:
        validSubscription === false ||
        petOrders.every(
          (i) => i.total_daily_grams < ENV.TWO_SHIPPINGS_THRESHOLD
        ),
    },
  ]);

  // useEffect(() => {
  //   setDeliveriesActions(
  //     deliveriesActions.map((a) => ({
  //       ...a,
  //       isDisabled: validSubscription === false,
  //     }))
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [validSubscription]);

  useEffect(() => {
    getDeliveriesPending();
    getDeliveriesHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  const {
    isOpen: isDeliveriesPostponeModalOpen,
    onOpen: onDeliveriesPostponeModalOpen,
    onClose: onDeliveriesPostponeModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeliveriesAddressModalOpen,
    onOpen: onDeliveriesAddressModalOpen,
    onClose: onDeliveriesAddressModalClose,
  } = useDisclosure();

  const {
    isOpen: isDeliveriesPauseOpen,
    onOpen: onDeliveriesPauseOpen,
    onClose: onDeliveriesPauseClose,
  } = useDisclosure();

  const {
    isOpen: isDeliveriesFrequencyOpen,
    onOpen: onDeliveriesFrequencyOpen,
    onClose: onDeliveriesFrequencyClose,
  } = useDisclosure();

  const getDeliveriesPending = async () => {
    setDeliveriesPendingLoading(true);

    deliveryService
      .delivery_pending({ expand: ["pet"] })
      .then(({ data }) => setDeliveriesPending(data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setDeliveriesPendingLoading(false));
  };

  const getDeliveriesHistory = async () => {
    setDeliveriesHistoryLoading(true);

    deliveryService
      .delivery_history({ expand: ["pet"] })
      .then(({ data }) => setDeliveriesHistory(data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setDeliveriesHistoryLoading(false));
  };

  return {
    user,
    petOrders,
    deliveriesPending,
    deliveriesPendingLoading,
    deliveriesHistory,
    deliveriesHistoryLoading,
    deliveriesActions,
    isDeliveriesPostponeModalOpen,
    onDeliveriesPostponeModalOpen,
    onDeliveriesPostponeModalClose,
    isDeliveriesAddressModalOpen,
    onDeliveriesAddressModalOpen,
    onDeliveriesAddressModalClose,
    isDeliveriesPauseOpen,
    onDeliveriesPauseOpen,
    onDeliveriesPauseClose,
    isDeliveriesFrequencyOpen,
    onDeliveriesFrequencyOpen,
    onDeliveriesFrequencyClose,
  };
};
