import {
  Alert,
  AlertIcon,
  Container,
  Flex,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { createContext, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  IJwtContext,
  ILoadingContext,
  IPetOrder,
  IUserContext,
} from "./types/types";

import Auth0 from "auth0";
import axios from "axios";
import Stripe from "stripe";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import NoMatch from "./components/NoMatch";
import Spinner from "./components/Spinner";
import Delivery from "./containers/Delivery";
import OrderCanceled from "./containers/OrderCanceled";
import OrderSuccess from "./containers/OrderSuccess";
import Auth from "./pages/Auth";
import Deliveries from "./pages/Deliveries";
import Home from "./pages/Home";
import Onboarding from "./pages/Onboarding";
import Pets from "./pages/Pets";
import Profile from "./pages/Profile";
import ResetPassword from "./pages/ResetPassword";
import { PetOrderService } from "./services/pet_order.service";
import { UserService } from "./services/user.service";

export const UserContext = createContext<IUserContext>({} as IUserContext);
export const JwtContext = createContext<IJwtContext>({} as IJwtContext);
export const LoadingContext = createContext<ILoadingContext>(
  {} as ILoadingContext
);

const userService = new UserService();
const petOrdersService = new PetOrderService();

const App: React.FC = () => {
  const [user, setUser] = useState<
    | {
        _id: string;
        stripe: Stripe.Customer;
        auth0: Auth0.User;
        active_plan: boolean;
      }
    | false
    | undefined
  >();
  const [petOrders, setPetOrders] = useState<IPetOrder[]>([]);
  const [jwt, setJwt] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const [validSubscription, setValidSubscription] = useState<boolean>();

  const saveJwt = () => {
    const local_jwt = localStorage.getItem("jwt");
    if (local_jwt) {
      setJwt(localStorage.getItem("jwt"));
    } else {
      setUser(false);
    }
  };

  useEffect(() => {
    saveJwt();
    window.addEventListener("storage", saveJwt);

    return () => {
      window.removeEventListener("storage", saveJwt);
    };
  }, []);

  useEffect(() => {
    getUser();
    getPetOrders();

    // Add a request interceptor
    axios.interceptors.request.use((config) => {
      let local_jwt = localStorage.getItem("jwt");
      if (local_jwt) config.headers!["Authorization"] = "Bearer " + local_jwt;

      return config;
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  useEffect(() => {
    if (user) {
      if (!user.active_plan) {
        setValidSubscription(false);
      } else {
        setValidSubscription(true);
      }
    }
  }, [user]);

  const getUser = () => {
    if (jwt) {
      userService
        .get()
        .then(({ data }) => {
          console.log("[getUser] data", data);
          setUser(data);
        })
        .catch((err) => {
          console.log("[getUser] err", err);
          setUser(false);

          let retry = false;
          if (retry) {
            // TO_DO: retry get user based on specific error
            getUser();
          }
        });
    }
  };

  const getPetOrders = () => {
    if (jwt) {
      petOrdersService.list({ expand: ["pet"] }).then(({ data }) => {
        console.log("[getPetOrders] data", data);
        setPetOrders(data);
      });
    }
  };

  return (
    <>
      <Flex direction={"column"} minH={"100vh"}>
        <UserContext.Provider
          value={{
            user,
            setUser,
            getUser,
            validSubscription,
            petOrders,
            setPetOrders,
            getPetOrders,
          }}
        >
          <LoadingContext.Provider value={{ loading, setLoading }}>
            <BrowserRouter>
              {user === false ? (
                <Routes>
                  <Route path="/auth" element={<Auth />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/onboarding/*" element={<Onboarding />} />
                  <Route path="*" element={<Navigate to="/auth" />} />
                </Routes>
              ) : user === undefined ? (
                <Spinner />
              ) : (
                <Container maxW={"container.xl"} p={0}>
                  <Flex direction={"column"} minH={"100vh"}>
                    <Navbar />
                    {validSubscription === false && (
                      <Alert status="error" borderRadius={"2xl"} my={4}>
                        <AlertIcon alignSelf={"flex-start"} />
                        <Flex
                          alignItems={"flex-start"}
                          justifyContent={"space-between"}
                          w={"full"}
                        >
                          <VStack
                            spacing={0}
                            justifyContent={"flex-start"}
                            alignItems={"flex-start"}
                          >
                            <Text fontWeight={"bold"}>
                              Tu suscripción está pausada.
                            </Text>
                            <Text>
                              Para reactivarla, contacta con nosotros en{" "}
                              <a
                                href={`mailto:ayuda@candidogmenu.com?subject=Reactivar suscripción&body=¡Hola!\n Me gustaría reactivar mi suscripción a Candi Dog Menu. \n¡Saludos!`}
                                style={{ textDecoration: "underline" }}
                              >
                                ayuda@candidogmenu.com
                              </a>
                              .
                            </Text>
                          </VStack>
                          {/* <Button
                            size={"sm"}
                            borderRadius={"full"}
                            colorScheme={"secondary"}
                            justifyContent={"space-between"}
                            alignSelf={"flex-end"}
                            isDisabled
                          >
                            Reactivar
                          </Button> */}
                        </Flex>
                      </Alert>
                    )}
                    <VStack flex={1} mb={8}>
                      <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/deliveries" element={<Deliveries />} />
                        <Route
                          path="/deliveries/:deliveryId"
                          element={<Delivery />}
                        />

                        <Route path="/pets" element={<Pets />} />
                        <Route path="/profile" element={<Profile />} />
                        <Route
                          path="/order-success"
                          element={<OrderSuccess />}
                        />
                        <Route
                          path="/order-canceled"
                          element={<OrderCanceled />}
                        />
                        <Route path="404" element={<NoMatch />} />
                        <Route path="/login" element={<Navigate to="/" />} />
                        <Route path="*" element={<Navigate to="/" />} />
                      </Routes>
                    </VStack>
                    <Footer />
                  </Flex>
                </Container>
              )}
            </BrowserRouter>
          </LoadingContext.Provider>
        </UserContext.Provider>
      </Flex>
      {loading && <Spinner />}
    </>
  );
};

export default App;
