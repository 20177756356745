import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Collapse,
  Flex,
  HStack,
  Icon,
  IconButton,
  Image,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { IconType } from "react-icons";
import { images } from "../assets/images/images";
import { ENV } from "../constants/environment.base";

const NavLink = ({
  caption,
  destination,
  icon,
}: {
  caption: string;
  destination: string;
  icon?: IconType;
}) => {
  const location = useLocation();

  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    setActive(
      window.location.pathname.split("/")[1] === destination.split("/")[1]
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Link to={destination}>
      <Box
        px={active ? 4 : 2}
        py={2}
        color={"gray.900"}
        fontWeight={active ? "semibold" : "medium"}
        _hover={{
          textDecor: "none",
        }}
        borderRadius={"full"}
        bgColor={active ? "brand.200" : "transparent"}
      >
        <Flex alignItems={"center"} justifyContent={"flex-end"}>
          <Text fontWeight={"semibold"} fontSize={"md"}>
            {caption}
          </Text>
          {icon && <Icon as={icon} ml={5} strokeWidth={2} h={5} w={5} />}
        </Flex>
      </Box>
    </Link>
  );
};

const Navbar: React.FC = () => {
  const location = useLocation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Box borderBottom={"gray.50"} py={1} mb={4}>
      <Flex
        h={16}
        alignItems={"center"}
        justifyContent={"space-between"}
        px={4}
      >
        <HStack h={"full"} spacing={8} alignItems={"center"}>
          <Box h={"full"} pos={"relative"} py={4}>
            <Link to="/">
              <Image src={images.logo_candi_yellow} h={"full"} />
            </Link>
          </Box>
          <HStack
            as={"nav"}
            pt={2}
            spacing={4}
            display={{ base: "none", md: "flex" }}
          >
            {ENV.NAVBAR_LINKS.map((link) => (
              <NavLink
                key={link.caption}
                caption={link.caption}
                destination={link.destination}
              />
            ))}
          </HStack>
        </HStack>
        <IconButton
          size={"lg"}
          variant={"ghost"}
          color={"brand.500"}
          icon={
            isOpen ? <CloseIcon h={4} w={4} /> : <HamburgerIcon h={6} w={8} />
          }
          aria-label={"Open Menu"}
          display={{ md: "none" }}
          onClick={isOpen ? onClose : onOpen}
          _focus={{
            bg: "none",
          }}
        />
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <Box p={4} bg={"gray.50"} display={{ md: "none" }}>
          <VStack as={"nav"} alignItems={"flex-end"}>
            {ENV.NAVBAR_LINKS.map((link) => (
              <NavLink
                key={link.caption}
                caption={link.caption}
                destination={link.destination}
                icon={link.icon}
              />
            ))}
          </VStack>
        </Box>
      </Collapse>
    </Box>
  );
};

export default Navbar;
