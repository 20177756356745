import axios, { AxiosResponse } from "axios";

import Auth0 from "auth0";
import Stripe from "stripe";
import { ENV } from "../constants/environment.base";
import { IUserExpanded } from "../types/types";

export class UserService {
  private readonly API_ROUTE_PREFIX: string = "user";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ENDPOINT;

  /**
   * Get user
   *
   * @returns Promise with user response
   */
  public get: () => Promise<
    AxiosResponse<{
      _id: string;
      stripe: Stripe.Customer;
      auth0: Auth0.User;
      active_plan: boolean;
    }>
  > = () => {
    return axios.get(`${this.API_PREFIX}/`);
  };

  /**
   * Update user
   *
   * @param params.email User email
   * @param params.phone_number User phone number
   *
   * @returns Promise with user update response
   */
  public update: (params: { email?: string; phone_number?: string }) => Promise<
    AxiosResponse<{
      user: IUserExpanded;
    }>
  > = ({ email, phone_number }) => {
    return axios.put(`${this.API_PREFIX}/`, {
      email,
      phone_number,
    });
  };

  /**
   * Update user address
   *
   * @param params.address Stripe Address
   *
   * @returns Promise with user address update response
   */
  public update_address: (params: { address: Stripe.Address }) => Promise<
    AxiosResponse<{
      address: Stripe.Customer;
    }>
  > = ({ address }) => {
    return axios.put(`${this.API_PREFIX}/user-address`, {
      address,
    });
  };

  /**
   * Pause subscription
   *
   * @param reason Reason for pausing subscription
   *
   * @returns Promise with subscription pause response
   */
  public pause_subscription: (params: {
    reason: string;
  }) => Promise<AxiosResponse<Stripe.Subscription>> = ({ reason }) => {
    return axios.post(`${this.API_PREFIX}/pause-subscription`, {
      reason,
    });
  };
}
