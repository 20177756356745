import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Image,
  Input,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  StackDivider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import { AddressElement, PaymentElement } from "@stripe/react-stripe-js";
import React, { useContext } from "react";
import {
  HiArrowPath,
  HiCheckCircle,
  HiChevronRight,
  HiXMark,
} from "react-icons/hi2";
import {
  calculateTransitionDeliveryDate,
  filterDates,
  getFormattedNumber,
} from "../constants/utils";
import useOnboardingStepPayment, {
  INCLUDED_FEATURES,
  IOnboardingStepPaymentTab,
} from "../hooks/useOnboardingStepPayment";

import DatePicker from "react-datepicker";
import { images } from "../assets/images/images";
import { ENV } from "../constants/environment.base";
import { IMenusChosen } from "../hooks/useOnboarding";
import { OnboardingContext } from "../pages/Onboarding";
import { IPetOrder } from "../types/types";

const OnboardingStepPayment = () => {
  const {
    validStep,
    errorMessages,
    showLocationWarning,
    password,
    setPassword,
    planStartDate,
    setPlanStartDate,
    maxCapacityDates,
    setStripeAddressElement,
    setStripePaymentElement,
    onboardingSubmitLoading,
    getDogMenuText,
    handleOnboardingComplete,
  } = useOnboardingStepPayment();

  /**
   * TO_DO: PWD COMPLEXITY
   *
   * Su contraseña debe contener:
   *   Al menos 8 caracteres de largo
   *   Letras minúsculas (a-z)
   *   Letras mayúsculas (A-Z)
   *   Números (0-9)
   */

  return (
    <>
      <VStack
        w={"full"}
        justifyContent={"center"}
        alignItems={"flex-start"}
        spacing={6}
      >
        <Button
          variant={"outline"}
          size={"sm"}
          leftIcon={<Icon as={HiArrowPath} />}
          onClick={() => {
            localStorage.clear();
            window.location.reload();
          }}
        >
          Volver a empezar
        </Button>
        <Heading as={"h1"} fontWeight="bold" fontSize={{ base: 25, md: 35 }}>
          Finalizar pedido
        </Heading>
      </VStack>
      <Grid
        templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(12, 1fr)" }}
        templateRows={"repeat(1, 1fr)"}
        gap={4}
        w={"full"}
      >
        <GridItem
          colSpan={{ base: 12, md: 8 }}
          bgColor={"white"}
          p={{ base: 4, md: 8 }}
          borderRadius={"xl"}
          order={{ base: 1, md: 0 }}
        >
          <VStack
            alignItems={"flex-start"}
            spacing={{ base: 4, md: 8 }}
            w={"full"}
          >
            <VStack
              alignItems={"flex-start"}
              spacing={{ base: 2, md: 4 }}
              w={"full"}
            >
              <Heading
                as={"h2"}
                fontWeight="semibold"
                fontSize={{ base: 20, md: 24 }}
              >
                Datos de acceso
              </Heading>
              <VStack w={"full"} spacing={2}>
                <FormControl id="password" isRequired>
                  <FormLabel fontWeight={"medium"}>Contraseña</FormLabel>
                  <Input
                    type="password"
                    size={"lg"}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                  />
                </FormControl>
              </VStack>
            </VStack>
            <VStack
              alignItems={"flex-start"}
              spacing={{ base: 2, md: 4 }}
              w={"full"}
            >
              <Heading
                as={"h2"}
                fontWeight="semibold"
                fontSize={{ base: 20, md: 24 }}
              >
                Dirección
              </Heading>
              <VStack w={"full"} spacing={2}>
                <Box w={"full"}>
                  <AddressElement
                    options={{
                      mode: "shipping",
                      allowedCountries: ["ES"],
                      display: { name: "split" },
                      autocomplete: {
                        mode: "disabled",
                      },
                    }}
                    onChange={(event) => {
                      console.log("event", event);
                      setStripeAddressElement(event);
                    }}
                  />
                </Box>
              </VStack>
            </VStack>
            <VStack
              alignItems={"flex-start"}
              spacing={{ base: 2, md: 4 }}
              w={"full"}
            >
              <Heading
                as={"h2"}
                fontWeight="semibold"
                fontSize={{ base: 20, md: 24 }}
              >
                Pago
              </Heading>
              <VStack w={"full"} spacing={2}>
                <Box w={"full"}>
                  <PaymentElement
                    options={{
                      layout: "auto",
                      business: { name: "Candi Dog Menu" },
                      fields: {
                        billingDetails: "never",
                      },
                    }}
                    onChange={(event) => {
                      console.log("event", event);
                      setStripePaymentElement(event);
                    }}
                  />
                </Box>
              </VStack>
            </VStack>
            {errorMessages &&
              errorMessages.length > 0 &&
              errorMessages.map((error) => (
                <List spacing={3} key={error}>
                  <ListItem alignItems={"center"} color={"red.500"}>
                    <ListIcon as={HiXMark} strokeWidth={1} h={5} w={5} />
                    {error}
                  </ListItem>
                </List>
              ))}
            {showLocationWarning && (
              <Alert status="error" fontSize={"sm"} borderRadius={"2xl"}>
                <AlertIcon alignSelf={"flex-start"} />
                Por el momento, solo estamos activos en Las Palmas, ¡te
                informaremos cuando estemos disponibles en tu zona!
              </Alert>
            )}
            <Button
              alignSelf={"flex-end"}
              size={"lg"}
              borderRadius={"full"}
              color={"white"}
              colorScheme="brand"
              type="submit"
              rightIcon={<Icon as={HiChevronRight} ml={10} />}
              justifyContent={"space-between"}
              isDisabled={!validStep}
              isLoading={onboardingSubmitLoading}
              loadingText="Últimas comprobaciones..."
              onClick={() => handleOnboardingComplete()}
            >
              Continuar
            </Button>
          </VStack>
        </GridItem>
        <GridItem
          colSpan={{ base: 12, md: 4 }}
          bgColor={"white"}
          p={{ base: 4, md: 8 }}
          borderRadius={"xl"}
          borderTopColor={"brand.500"}
          borderTopWidth={4}
          order={{ base: 0, md: 1 }}
        >
          <VStack
            alignItems={"flex-start"}
            spacing={{ base: 4, md: 8 }}
            w={"full"}
          >
            <VStack
              alignItems={"flex-start"}
              spacing={{ base: 2, md: 4 }}
              w={"full"}
            >
              <Heading
                as={"h2"}
                fontWeight="semibold"
                fontSize={{ base: 20, md: 24 }}
              >
                Comienzo
              </Heading>
              <DatePicker
                selected={planStartDate}
                locale="es"
                dateFormat="dd/MM/yyyy"
                filterDate={(d) => filterDates(d, maxCapacityDates)}
                minDate={calculateTransitionDeliveryDate()}
                onChange={(date) => setPlanStartDate(date!)}
              />
            </VStack>
            <VStack
              alignItems={"flex-start"}
              spacing={{ base: 2, md: 4 }}
              w={"full"}
            >
              <Heading
                as={"h2"}
                fontWeight="semibold"
                fontSize={{ base: 20, md: 24 }}
              >
                Resumen del plan
              </Heading>
              <VStack w={"full"} spacing={{ base: 4, md: 8 }}>
                <Tabs
                  variant="soft-rounded"
                  colorScheme="secondary"
                  size={"md"}
                  w={"full"}
                >
                  <TabList
                    mx={"auto"}
                    mb={4}
                    bgColor={"gray.100"}
                    p={1}
                    borderRadius={"3xl"}
                    w={"full"}
                    justifyContent={"space-around"}
                  >
                    {["Primeras 2 semanas", "Mensual"].map((tab) => (
                      <Tab
                        key={tab}
                        _selected={{
                          color: "white",
                          bgColor: "secondary.300",
                        }}
                        fontSize={{ base: 13, md: 14 }}
                        px={{ base: 1, md: 2 }}
                        borderRadius={"2xl"}
                        w={"full"}
                      >
                        {tab}
                      </Tab>
                    ))}
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <SummaryTab
                        onboarding={true}
                        getDogMenuText={getDogMenuText}
                      />
                    </TabPanel>
                    <TabPanel>
                      <SummaryTab getDogMenuText={getDogMenuText} />
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <VStack
                  w={"full"}
                  spacing={{ base: 2, md: 4 }}
                  justifyContent={"flex-start"}
                >
                  <Heading
                    as={"h3"}
                    w={"full"}
                    fontWeight="medium"
                    fontSize={{ base: 18, md: 20 }}
                  >
                    ¿Qué me incluye?
                  </Heading>
                  <VStack w={"full"} alignItems={"flex-start"} spacing={4}>
                    {INCLUDED_FEATURES.map((feature) => (
                      <HStack
                        key={feature}
                        alignItems={"flex-start"}
                        spacing={2}
                      >
                        <Icon
                          as={HiCheckCircle}
                          color={"green.500"}
                          h={6}
                          w={6}
                        />
                        <Text w={"full"} fontSize={{ base: "sm", md: "md" }}>
                          {feature}
                        </Text>
                      </HStack>
                    ))}
                  </VStack>
                </VStack>
              </VStack>
            </VStack>
          </VStack>
        </GridItem>
      </Grid>
    </>
  );
};

const SummaryTab: React.FC<IOnboardingStepPaymentTab> = ({
  onboarding = false,
  getDogMenuText,
}) => {
  const {
    data,
    menusChosen,
    setMenusChosen,
    planPriceSubtotal,
    planPriceTotal,
  } = useContext(OnboardingContext);

  const {
    isOpen: isEditMenuOpen,
    onOpen: onEditMenuOpen,
    onClose: onEditMenuClose,
  } = useDisclosure();

  const [petOrderToEdit, setPetOrderToEdit] = React.useState<IPetOrder>();
  const [newMenus, setNewMenus] = React.useState<IMenusChosen>();

  // useEffect(() => {}, [menusChosen]);

  const handleMenuEdit = (
    pet_order_id: string,
    menu: string,
    newValue: boolean
  ) => {
    let new_menus = { ...newMenus };

    if (newValue === false) {
      new_menus[pet_order_id] = new_menus[pet_order_id].filter(
        (m) => m !== menu
      );
    } else if (newValue === true && !new_menus[pet_order_id].includes(menu)) {
      new_menus[pet_order_id].push(menu);
    }

    setNewMenus(new_menus);
  };

  const handleMenuEditSubmit = (pet_order_id: string) => {
    let new_menus_chosen = { ...(menusChosen as IMenusChosen) };
    new_menus_chosen[pet_order_id] = [...newMenus![pet_order_id]];

    console.log("new_menus_chosen", new_menus_chosen);
    console.log("menusChosen", menusChosen);

    setMenusChosen(new_menus_chosen);
    onEditMenuClose();
  };

  return (
    <>
      <VStack spacing={{ base: 5, md: 8 }} w={"full"}>
        <VStack divider={<StackDivider borderColor="gray.200" />} w={"full"}>
          <VStack
            w={"full"}
            spacing={{ base: 1, md: 2 }}
            justifyContent={"flex-start"}
          >
            <HStack justifyContent={"space-between"} w={"full"}>
              <Text>Subtotal (inc. IGIC 3%)</Text>

              <Text fontWeight={"medium"}>
                {planPriceSubtotal
                  ? `${getFormattedNumber(
                      !onboarding ? planPriceSubtotal : planPriceSubtotal / 2
                    )}`
                  : "Error"}
              </Text>
            </HStack>
            <HStack justifyContent={"space-between"} w={"full"}>
              <Text fontWeight={"medium"} color={"green.500"}>
                Gastos de envío
              </Text>
              <Text fontWeight={"medium"} color={"green.500"}>
                ¡GRATIS!
              </Text>
            </HStack>
          </VStack>
          <HStack justifyContent={"space-between"} w={"full"}>
            <Text fontWeight={"semibold"}>TOTAL</Text>

            <Text fontWeight={"semibold"}>
              {planPriceTotal
                ? `${getFormattedNumber(
                    !onboarding ? planPriceTotal : planPriceTotal / 2
                  )}`
                : "Error"}
            </Text>
          </HStack>
        </VStack>
        <VStack
          w={"full"}
          spacing={{ base: 2, md: 4 }}
          justifyContent={"flex-start"}
        >
          <Heading
            as={"h3"}
            w={"full"}
            fontWeight="medium"
            fontSize={{ base: 18, md: 20 }}
          >
            ¿Qué recibiré?
          </Heading>
          <VStack w={"full"} spacing={6}>
            {onboarding && (
              <Alert colorScheme="brand" fontSize={"sm"} borderRadius={"2xl"}>
                <AlertIcon alignSelf={"flex-start"} />
                Las primeras dos semanas serán de transición a la nueva
                alimentación y recibirás un 50% de la cantidad total de comida.
              </Alert>
            )}
            <VStack
              divider={<StackDivider borderColor="gray.200" />}
              w={"full"}
              spacing={4}
            >
              {data.pet_orders.map((pet_order) => (
                <VStack
                  key={pet_order._id}
                  w={"full"}
                  alignItems={"flex-start"}
                  spacing={3}
                >
                  <HStack
                    justifyContent={"space-between"}
                    w={"full"}
                    key={pet_order.pet as string}
                  >
                    <Text fontWeight={"medium"}>
                      {data.pets.find((p) => p._id === pet_order.pet)?.name}
                    </Text>
                    <HStack>
                      <Tag colorScheme="brand">
                        {pet_order.total_daily_grams}g diarios
                      </Tag>
                    </HStack>
                  </HStack>
                  <Text fontSize={"sm"} color={"gray.500"}>
                    {getDogMenuText(pet_order, "daily", onboarding)}
                    <br />
                    {getDogMenuText(pet_order, "monthly", onboarding)}
                  </Text>
                  <Button
                    variant={"link"}
                    colorScheme="secondary"
                    onClick={() => {
                      setNewMenus(menusChosen);
                      setPetOrderToEdit(pet_order);
                      onEditMenuOpen();
                    }}
                  >
                    Editar menú →
                  </Button>
                </VStack>
              ))}
            </VStack>
          </VStack>
        </VStack>
      </VStack>
      {petOrderToEdit && newMenus && (
        <Modal
          isOpen={isEditMenuOpen}
          onClose={onEditMenuClose}
          isCentered={true}
          size={"2xl"}
        >
          <ModalOverlay />
          <ModalContent borderRadius={"xl"} padding={4}>
            <ModalHeader>
              <Heading as={"h2"} size="md">
                Menú de{" "}
                {data.pets.find((p) => p._id === petOrderToEdit.pet)?.name}
              </Heading>
            </ModalHeader>
            <ModalCloseButton top={8} right={8} />
            <ModalBody>
              <SimpleGrid
                spacing={8}
                columns={{ base: 1, md: 2 }}
                w={"fit-content"}
                margin={"0 auto"}
                py={4}
              >
                {Object.keys(ENV.MENUS_CAPTIONS).map((menu) => (
                  <VStack
                    key={menu}
                    spacing={3}
                    p={6}
                    h={"fit-content"}
                    w={"fit-content"}
                    aspectRatio={"1 / 1"}
                    borderWidth={"3px"}
                    borderRadius={"xl"}
                    borderColor="secondary.500"
                    color="secondary.500"
                  >
                    <Image
                      src={images[`${menu}_menu`]}
                      h={"150px"}
                      borderRadius={"lg"}
                    />
                    <Text
                      fontWeight={"semibold"}
                      fontSize={20}
                      fontFamily={"Braveold"}
                    >
                      {ENV.MENUS_CAPTIONS[menu]}
                    </Text>
                    <Checkbox
                      size="lg"
                      colorScheme="brand"
                      isChecked={newMenus[petOrderToEdit._id].some(
                        (m) => m === menu
                      )}
                      onChange={(e) => {
                        handleMenuEdit(
                          petOrderToEdit._id,
                          menu,
                          e.target.checked
                        );
                      }}
                    />
                  </VStack>
                ))}
              </SimpleGrid>
            </ModalBody>
            <ModalFooter>
              <Button
                size={"md"}
                borderRadius={"full"}
                colorScheme={"brand"}
                justifyContent={"space-between"}
                rightIcon={<Icon as={HiChevronRight} ml={10} />}
                isDisabled={newMenus[petOrderToEdit._id].length === 0}
                onClick={() => handleMenuEditSubmit(petOrderToEdit._id)}
              >
                Guardar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default OnboardingStepPayment;
