import adult from "./files/adult.svg";
import auth_bg_pattern from "./files/auth_bg_pattern.svg";
import beef from "./files/beef.svg";
import beef_menu from "./files/beef_menu.svg";
import chicken from "./files/chicken.svg";
import chicken_menu from "./files/chicken_menu.svg";
import dog_outside from "./files/dog_outside.svg";
import dog_sitting from "./files/dog_sitting.svg";
import fish_menu from "./files/fish_menu.svg";
import ideal from "./files/ideal.svg";
import logo_candi_black from "./files/logo_candi_black.svg";
import logo_candi_white from "./files/logo_candi_white.svg";
import logo_candi_yellow from "./files/logo_candi_yellow.svg";
import obese from "./files/obese.svg";
import orange_dog from "./files/orange_dog.svg";
import overweight from "./files/overweight.svg";
import pig from "./files/pig.svg";
import pig_menu from "./files/pig_menu.svg";
import puppy from "./files/puppy.svg";
import senior from "./files/senior.svg";
import thin from "./files/thin.svg";
import very_thin from "./files/very_thin.svg";

interface IImages {
  [key: string]: string;
  logo_candi_black: string;
  logo_candi_white: string;
  logo_candi_yellow: string;
  chicken: string;
  beef: string;
  pig: string;
  dog_sitting: string;
  dog_outside: string;
  orange_dog: string;
  auth_bg_pattern: string;
  puppy: string;
  adult: string;
  senior: string;
  very_thin: string;
  thin: string;
  ideal: string;
  overweight: string;
  obese: string;
  beef_menu: string;
  chicken_menu: string;
  pig_menu: string;
  fish_menu: string;
}

export const images: IImages = {
  logo_candi_black: logo_candi_black,
  logo_candi_white: logo_candi_white,
  logo_candi_yellow: logo_candi_yellow,
  chicken: chicken,
  beef: beef,
  pig: pig,
  dog_sitting: dog_sitting,
  dog_outside: dog_outside,
  orange_dog: orange_dog,
  auth_bg_pattern: auth_bg_pattern,
  puppy: puppy,
  adult: adult,
  senior: senior,
  very_thin: very_thin,
  thin: thin,
  ideal: ideal,
  overweight: overweight,
  obese: obese,
  beef_menu: beef_menu,
  chicken_menu: chicken_menu,
  pig_menu: pig_menu,
  fish_menu: fish_menu,
};
