import axios, { AxiosResponse } from "axios";

import Stripe from "stripe";
import { ENV } from "../constants/environment.base";

export class StripeService {
  private readonly API_ROUTE_PREFIX: string = "stripe";
  private readonly API_PREFIX: string = `${ENV.API_ENDPOINT}/${this.API_ROUTE_PREFIX}`;

  /**
   * List client payment methods
   *
   * @param customer_id Stripe customer ID
   *
   * @returns Promise with payment method listing response
   */
  public list_payment_methods: (
    customer_id: string
  ) => Promise<AxiosResponse<{ payment_methods: Stripe.PaymentMethod[] }>> = (
    customer_id
  ) => {
    return axios.get(`${this.API_PREFIX}/payment-methods`, {
      params: {
        customer_id,
      },
    });
  };

  /**
   * TO_DO: update function name and params typing
   */
  /**
   * Create client secret
   *
   * @param customer_id Stripe customer ID
   *
   * @returns Promise with create client secret response
   */
  public create_setup_intent: (
    customer_id: string
  ) => Promise<AxiosResponse<any, any>> = (customer_id) => {
    return axios.post(`${this.API_PREFIX}/setup-intent`, {
      customer_id,
    });
  };

  /**
   * Get Stripe customer
   *
   * @param customer_id Stripe customer ID
   *
   * @returns Promise with customer response
   */
  public customer: (
    customer_id: string
  ) => Promise<AxiosResponse<Stripe.Customer>> = (customer_id) => {
    return axios.get(`${this.API_PREFIX}/customer/${customer_id}`);
  };

  /**
   * Create Stripe customer
   *
   * @param data Object containing customer data
   *
   * @returns Promise with customer create response
   */
  public create_customer: (
    data: Stripe.CustomerCreateParams
  ) => Promise<AxiosResponse<Stripe.Customer>> = (data) => {
    return axios.post(`${this.API_PREFIX}/customer`, { data });
  };

  /**
   * Update Stripe customer
   *
   * @param customer_id Stripe customer ID
   * @param data Object containing new customer data
   *
   * @returns Promise with customer update response
   */
  public update_customer: (
    customer_id: string,
    data: Stripe.CustomerUpdateParams
  ) => Promise<AxiosResponse<Stripe.Customer>> = (customer_id, data) => {
    return axios.put(`${this.API_PREFIX}/customer`, { customer_id, data });
  };

  /**
   * Postpone subscription
   *
   * @param customer_id Stripe customer ID
   * @param new_date New billing cycle date in UNIX timestamp
   *
   * @returns Promise with subscription postpone response
   */
  public postpone_subscription: (params: {
    customer_id: string;
    new_date: number;
  }) => Promise<AxiosResponse<Stripe.Subscription>> = ({
    customer_id,
    new_date,
  }) => {
    return axios.post(`${this.API_PREFIX}/postpone-subscription`, {
      customer_id,
      new_date,
    });
  };
}
