import { HStack, Heading, IconButton } from "@chakra-ui/react";

import React from "react";
import { HiArrowLeft } from "react-icons/hi2";
import { Link } from "react-router-dom";

const PageHeader: React.FC<{
  title?: string;
  backButton?: { destination: string };
}> = ({ title, backButton }) => {
  return (
    <>
      <HStack justifyContent={"start"} alignItems={"center"} px={4} spacing={3}>
        {backButton && (
          <Link to={backButton?.destination}>
            <IconButton
              icon={<HiArrowLeft />}
              borderRadius={"2xl"}
              aria-label="Go back"
            />
          </Link>
        )}
        <Heading as="h1" size="xl" mt={2}>
          {title}
        </Heading>
      </HStack>
    </>
  );
};

export default PageHeader;
