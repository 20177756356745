import axios, { AxiosResponse } from "axios";
import { IMenusChosen, IOnboardingDataPet } from "../hooks/useOnboarding";
import { PublicPetOrders, PublicPets, PublicUsers } from "../types/db.schema";

import Stripe from "stripe";
import { ENV } from "../constants/environment.base";

export class OnboardingService {
  private readonly API_ROUTE_PREFIX: string = "onboarding";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ENDPOINT;

  /**
   * Initial user creation on Onboarding
   *
   * @param data User data
   *
   * @returns Promise with user create response
   */
  public onboarding_initial: (
    user_email: string,
    user_phone_number: string,
    pets: IOnboardingDataPet[]
  ) => Promise<
    AxiosResponse<{
      user: PublicUsers;
      pets: PublicPets[];
      pet_orders: PublicPetOrders[];
      stripe_payment_intent_client_secret: string;
      plan_total_amount: number;
    }>
  > = (user_email, user_phone_number, pets) => {
    return axios.post(`${this.API_PREFIX}/initial`, {
      user_email,
      user_phone_number,
      pets,
    });
  };

  /**
   * Get max capacity dates
   *
   * @param min_date Min date
   *
   * @returns Promise with max capacity dates
   */
  public max_capacity_dates: (min_date: Date) => Promise<
    AxiosResponse<{
      max_capacity_dates: Date[];
    }>
  > = (min_date) => {
    return axios.get(`${this.API_PREFIX}/max-capacity-dates`, {
      params: {
        min_date: min_date,
      },
    });
  };

  /**
   * Complete onboarding
   *
   */
  public onboarding_complete: ({
    stripe_id,
    auth0_password,
    name,
    address,
    plan_price_total,
    total_daily_grams,
    plan_start_date,
    payment_id,
    menus,
  }: {
    stripe_id: string;
    auth0_password: string;
    name: {
      given_name: string;
      family_name: string;
    };
    address: Stripe.AddressParam;
    plan_price_total: number;
    total_daily_grams: number;
    plan_start_date: string;
    payment_id: string;
    menus: IMenusChosen;
  }) => Promise<
    AxiosResponse<{
      ok: boolean;
    }>
  > = ({
    stripe_id,
    auth0_password,
    name,
    address,
    plan_price_total,
    total_daily_grams,
    plan_start_date,
    payment_id,
    menus,
  }) => {
    return axios.post(`${this.API_PREFIX}/complete`, {
      stripe_id,
      auth0_password,
      name,
      address,
      plan_price_total,
      total_daily_grams,
      plan_start_date,
      payment_id,
      menus,
    });
  };
}
