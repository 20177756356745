import { useState } from "react";

import { ERROR_MESSAGES } from "../constants/error_messages";
import { AuthService } from "../services/auth.service";

const authService = new AuthService();

export const useResetPassword = () => {
  const [email, setEmail] = useState<string>("");
  const [emailSent, setEmailSent] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>();

  const handlePwdResetRequest = () => {
    setLoading(true);

    authService
      .requestResetPassword(email)
      .then(({ data }) => {
        console.log("data", data);

        setErrorMsg(undefined);
        setEmailSent(true);
      })
      .catch((err) => {
        console.error(err);
        setErrorMsg(
          ERROR_MESSAGES[err.response.data.code] || err.response.data.code
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    email,
    setEmail,
    emailSent,
    loading,
    errorMsg,
    handlePwdResetRequest,
  };
};
