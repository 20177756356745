import axios, { AxiosResponse } from "axios";

import { ENV } from "../constants/environment.base";
import { IDelivery } from "../types/types";

export class DeliveryService {
  private readonly API_ROUTE_PREFIX: string = "delivery";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ENDPOINT;

  /**
   * Get user pending deliveries
   *
   * @returns Promise with deliveries response
   */
  public delivery_pending: (options?: {
    expand?: Array<string>;
  }) => Promise<AxiosResponse<IDelivery[]>> = (options) => {
    return axios.get(`${this.API_PREFIX}/pending`, { params: { ...options } });
  };

  /**
   * Get user delivery history
   *
   * @returns Promise with deliveries response
   */
  public delivery_history: (options?: {
    expand?: Array<string>;
  }) => Promise<AxiosResponse<IDelivery[]>> = (options) => {
    return axios.get(`${this.API_PREFIX}/history`, { params: { ...options } });
  };

  /**
   * Get delivery detail
   *
   * @param id Delivery ID
   *
   * @returns Promise with delivery detail response
   */
  public delivery: (
    id: string,
    options?: { expand?: Array<string> }
  ) => Promise<AxiosResponse<IDelivery>> = (id, options) => {
    return axios.get(`${this.API_PREFIX}/${id}`, { params: { ...options } });
  };
}
