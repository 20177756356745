import { useContext, useEffect, useState } from "react";
import { JwtContext, UserContext } from "../App";

import { PetService } from "../services/pet.service";
import { IPet } from "../types/types";

const petsService = new PetService();

export const usePets = () => {
  const { jwt } = useContext(JwtContext);
  const { petOrders } = useContext(UserContext);

  const [pets, setPets] = useState<IPet[] | undefined>();
  const [petsLoading, setPetsLoading] = useState<boolean>(false);

  useEffect(() => {
    getPets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jwt]);

  const getPets = async () => {
    setPetsLoading(true);

    petsService
      .list()
      .then(({ data }) => setPets(data))
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setPetsLoading(false));
  };

  const getDogMenuText = (daily_bags: {
    [grams: string]: number;
  }): string | undefined => {
    let string = "debe comer diariamente ";

    Object.keys(daily_bags).forEach((key, keyIndex) => {
      if (
        keyIndex === Object.keys(daily_bags).length - 1 &&
        keyIndex !== 0 &&
        Object.keys(daily_bags).length !== 1
      ) {
        string += ` y ${daily_bags[key]} ${
          daily_bags[key] > 1 ? "bolsitas" : "bolsita"
        } de ${key}g`;
        return;
      }

      string += `${daily_bags[key]} ${
        daily_bags[key] > 1 ? "bolsitas" : "bolsita"
      } de ${key}g`;
    });

    return (string += ".");
  };

  return {
    pets,
    petsLoading,
    petOrders,
    getDogMenuText,
  };
};
