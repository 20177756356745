import {
  Box,
  Flex,
  UseRadioProps,
  useRadio,
  useRadioGroup,
} from "@chakra-ui/react";
import React, { ReactElement } from "react";

import { HTMLChakraProps } from "@chakra-ui/system";

interface IRadioButton extends UseRadioProps {
  children: ReactElement;
  childrenStyle?: HTMLChakraProps<"div">;
}

const RadioButton: React.FC<IRadioButton> = (props) => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box as="label" {...props.childrenStyle}>
      <input {...input} />
      <Box
        {...checkbox}
        cursor={"pointer"}
        borderWidth={"2px"}
        borderRadius={"2xl"}
        fontWeight={"medium"}
        color={"gray.800"}
        _checked={{
          bg: "secondary.600",
          borderColor: "secondary.600",
          color: "white",
        }}
        px={4}
        py={2}
      >
        {props.children}
      </Box>
    </Box>
  );
};

interface IRadioButtonGroup {
  name: string;
  defaultValue?: string;
  value: string;
  onChange: (value: string) => void;
  options: { value: string; caption: string }[];
  groupStyle?: HTMLChakraProps<"div">;
  childrenStyle?: HTMLChakraProps<"div">;
}

const RadioButtonGroup: React.FC<IRadioButtonGroup> = ({
  name,
  defaultValue,
  value,
  onChange,
  options,
  groupStyle,
  childrenStyle,
}) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const group = getRootProps();

  return (
    <Flex
      gap={2}
      wrap={"wrap"}
      justifyContent={"center"}
      {...group}
      {...groupStyle}
    >
      {options.map((option) => (
        <RadioButton
          key={option.value}
          childrenStyle={{ ...childrenStyle }}
          {...getRadioProps({ value: option.value })}
        >
          <>{option.caption}</>
        </RadioButton>
      ))}
    </Flex>
  );
};

export default RadioButtonGroup;
