import {
  HStack,
  Heading,
  Image,
  Skeleton,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { ReactNode, useEffect, useState } from "react";
import { IDelivery, IPet } from "../types/types";

import moment from "moment";
import { useParams } from "react-router-dom";
import { images } from "../assets/images/images";
import PageHeader from "../components/PageHeader";
import { ENV } from "../constants/environment.base";
import { DeliveryService } from "../services/delivery.service";
import Section from "./Section";

const deliveryService = new DeliveryService();

const Delivery: React.FC = () => {
  const params = useParams();

  const [delivery, setDelivery] = useState<IDelivery | undefined>();
  const [deliveryLoading, setDeliveryLoading] = useState<boolean>(false);

  useEffect(() => {
    if (params.deliveryId) {
      getDelivery(params.deliveryId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDelivery = async (id: string) => {
    setDeliveryLoading(true);

    deliveryService
      .delivery(id, { expand: ["pet"] })
      .then(({ data }) => setDelivery(data))
      .catch((err) => {
        throw new Error(err);
      })
      .finally(() => setDeliveryLoading(false));
  };

  const getDogBagsText = (delivery: IDelivery): string | undefined => {
    const daily_bags: { [grams: string]: number } = delivery.content.daily_bags;
    const total_daily_grams: number = delivery.content.total_daily_grams;

    const monthly_bags: { [grams: string]: number } = {};
    Object.keys(daily_bags).forEach((key) => {
      monthly_bags[key] = !delivery.is_trial
        ? total_daily_grams < 500
          ? daily_bags[key] * 28
          : daily_bags[key] * 14
        : daily_bags[key] * 14;
    });

    let string = "Recibirás";

    Object.keys(monthly_bags).forEach((key, keyIndex) => {
      if (
        keyIndex === Object.keys(monthly_bags).length - 1 &&
        keyIndex !== 0 &&
        Object.keys(monthly_bags).length !== 1
      ) {
        string += ` y ${monthly_bags[key]} ${
          monthly_bags[key] > 1 ? "bolsitas" : "bolsita"
        } de ${key}g`;
        return;
      }

      string += ` ${monthly_bags[key]} ${
        monthly_bags[key] > 1 ? "bolsitas" : "bolsita"
      } de ${key}g`;
    });

    return (string += ".");
  };

  return (
    <VStack
      w={"full"}
      spacing={8}
      alignItems={"start"}
      justifyContent={"start"}
      textAlign={"start"}
    >
      <PageHeader
        title={`Detalle del envío ${
          delivery ? `para ${(delivery.pet as IPet).name}` : ""
        }`}
        backButton={{ destination: "/deliveries" }}
      />
      <Section>
        <Skeleton isLoaded={!deliveryLoading}>
          {delivery && (
            <VStack
              textAlign={"start"}
              w={"full"}
              alignItems={"start"}
              justifyContent={"start"}
              divider={<StackDivider color={"red.200"} />}
            >
              <DeliveryInfoLine
                caption={"Estado"}
                value={
                  <Text
                    color={
                      delivery.status === "cancelled"
                        ? "red.500"
                        : delivery.status === "delivered"
                        ? "green.500"
                        : "yellow.500"
                    }
                    fontWeight={"medium"}
                    casing={"capitalize"}
                  >
                    {ENV.DELIVERY_KEY_CAPTIONS[delivery.status]}
                  </Text>
                }
              />
              <DeliveryInfoLine
                caption={"Fecha de entrega"}
                value={
                  <Text>
                    {moment(delivery.delivery_date).format("D MMMM YYYY")}
                  </Text>
                }
              />
              <DeliveryInfoLine
                caption={"Dirección de entrega"}
                value={
                  <Text>
                    {delivery.address.line1}{" "}
                    {delivery.address.line2 && `, ${delivery.address.line2}`}
                    <br />
                    {delivery.address.postal_code}
                    {" - "}
                    {delivery.address.city}
                  </Text>
                }
              />
              <DeliveryInfoLine
                caption={"Contenido del envío"}
                value={
                  <VStack alignItems={"flex-start"}>
                    <Text>{getDogBagsText(delivery)}</Text>
                    <HStack justifyContent={"flex-start"} spacing={4}>
                      <HStack>
                        <Image src={images.chicken} h={"20px"} />
                        <Text>{delivery.content.menu.chicken}</Text>
                      </HStack>
                      <HStack>
                        <Image src={images.beef} h={"20px"} />
                        <Text>{delivery.content.menu.beef}</Text>
                      </HStack>
                      {/* <HStack>
                        <Image src={images.pig} h={"20px"} />
                        <Text>{delivery.content.menu.pig}</Text>
                      </HStack> */}
                    </HStack>
                  </VStack>
                }
              />
            </VStack>
          )}
        </Skeleton>
      </Section>
    </VStack>
  );
};

const DeliveryInfoLine: React.FC<{ caption: string; value: ReactNode }> = ({
  caption,
  value,
}) => {
  return (
    <VStack justifyContent={"flex-start"} alignItems={"flex-start"} py={2}>
      <Heading as={"h2"} size={"md"}>
        {caption}
      </Heading>
      {value}
    </VStack>
  );
};

export default Delivery;
