import { Button, Heading } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ENV } from "../constants/environment.base";

const OrderSuccess: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <Heading as="h3">Subscription to starter plan successful!</Heading>
        </div>
      </div>
      <form action={`${ENV.API_ENDPOINT}/create-portal-session`} method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={location?.state?.sessionId || ""}
        />
        <Button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </Button>
      </form>
    </section>
  );
};

export default OrderSuccess;
