import axios, { AxiosResponse } from "axios";

import { ENV } from "../constants/environment.base";
import { IPetOrder } from "../types/types";

export class PetOrderService {
  private readonly API_ROUTE_PREFIX: string = "pet-order";
  private readonly API_PREFIX: string =
    this.API_ROUTE_PREFIX !== ""
      ? `${ENV.API_ENDPOINT}/${this.API_ROUTE_PREFIX}`
      : ENV.API_ENDPOINT;

  /**
   * List all pet orders
   *
   * @returns Promise with pet orders response
   */
  public list: (options?: {
    expand?: Array<string>;
  }) => Promise<AxiosResponse<IPetOrder[]>> = (options) => {
    return axios.get(`${this.API_PREFIX}/list`, {
      params: { ...options },
    });
  };

  /**
   * Update delivery frequency
   *
   * @param data Delivery frequency update data
   * @param data.pet_order Pet order ID
   * @param data.frequency Delivery frequency
   *
   * @returns Promise with delivery frequency update response
   */
  public update_deliveries_frequency: (data: {
    pet_order: string;
    frequency: 1 | 2;
  }) => Promise<AxiosResponse<IPetOrder>> = (data) => {
    return axios.put(`${this.API_PREFIX}/frequency`, { data: data });
  };
}
