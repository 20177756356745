import moment from "moment";
import momentBusinessDays from "moment-business-days";
import { ENV } from "./environment.base";

/**
 * Get breed name from code
 *
 * @param code
 *
 * @returns breed name if exists
 */
export const getBreedName = (code: number) => {
  const names = ENV.DOG_BREEDS.filter((b) => b.code === code);

  if (names.length === 0) {
    return undefined;
  }

  return names[0].name;
};

/**
 * Validate email format
 *
 * @param email
 *
 * @returns true if email is valid and false otherwise
 */
export const validateEmail = (email: string) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
};

/**
 * Get formatted number
 *
 * @param number
 *
 * @returns formatted number
 */
export const getFormattedNumber = (number: number) => {
  return number.toLocaleString("es-ES", {
    useGrouping: true,
    minimumFractionDigits: 2,
    currency: "EUR",
    currencyDisplay: "narrowSymbol",
    style: "currency",
  });
};

/**
 * Filter delivery dates
 *
 * @param date
 * @param max_capacity_dates dates to exclude
 *
 * @returns days to exclude
 */
export const filterDates = (date: Date, max_capacity_dates?: Date[]) => {
  // If it's a weekend day, return false
  if (date.getDay() === 0 || date.getDay() === 6) {
    return false;
  }

  // If it's a holiday, return false
  if (ENV.HOLIDAYS.some((d) => moment(d.date).isSame(date, "day"))) {
    return false;
  }

  // If it's a max capacity date, return false
  if (
    max_capacity_dates &&
    max_capacity_dates.some((d) => moment(d).isSame(date, "day"))
  ) {
    return false;
  }

  return true;
};

/**
 * Calculate transition delivery date.
 * If the delivery date is a weekend day or a holiday, it will return the next available date.
 *
 * @returns transition delivery date
 */
export const calculateTransitionDeliveryDate = () => {
  const result = momentBusinessDays(
    moment(new Date()).format("DD-MM-YYYY"),
    "DD-MM-YYYY"
  )
    .businessAdd(5)
    .toDate();

  return result;
};
